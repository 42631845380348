export function usernameValidator(username) {
  if (/^[a-z0-9][a-z0-9_-]{0,13}[a-z0-9]$/.test(username)) {
    return true;
  } else {
    return false;
  }
}

export function passwordValidator(password) {
  if (password.length >= 8) {
    return true;
  } else {
    return false;
  }
}

export function inboxIdValidator(inboxId) {
  if (/^[a-z0-9][a-z0-9_-]{0,13}[a-z0-9]$/.test(inboxId)) {
    return true;
  } else {
    return false;
  }
}

export function randomAlphaNumeric(length) {
  return Math.round(
    Math.pow(36, length + 1) - Math.random() * Math.pow(36, length)
  )
    .toString(36)
    .slice(1);
}
