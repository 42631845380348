import React from "react";
import { ReactComponent as RelayBeam } from "../../graphics/RelayBeam.svg";
import { ArrowRight, CheckCircle, Linkedin } from "react-bootstrap-icons";
import "./About.css";
import { Link } from "@chakra-ui/react";

const About = () => {
  return (
    <div className="about-page">
      <div className="content-wrapper">
        <header className="header">
          <div className="logo-title">
            <RelayBeam className="relaybeam-logo" />
          </div>
          <p className="subtitle">Redefining Messaging for the Digital Age</p>
        </header>

        <main className="main-content">
          <section className="section">
            <h2 className="section-title">Quick Description</h2>
            <p className="section-text">
              RelayBeam is a{" "}
              <span style={{ fontWeight: "bold" }}>
                Multi-Port Real-Time Contextual Messaging Platform
              </span>
              . With a unique multi-port feature; it organizes conversations
              based on context through the user-defined ports. (Users don’t need
              an email address to use RelayBeam - It is completely independent
              of email and does not even use it internally)
            </p>
          </section>

          <section className="section">
            <h2 className="section-title">Why RelayBeam?</h2>
            <p className="section-text">
              Traditional communication tools such as email and scattered
              messaging apps are inefficient and disconnected, leading to
              information overload and difficulty in staying organized and
              prioritizing tasks. Individuals and teams struggle to manage
              multiple communication channels and platforms for different
              contexts, this hinders productivity and causes frustration.
            </p>
            <p className="section-text">
              Following are some limitations faced by email and other messaging
              platforms:
            </p>
            <ul className="challenge-list">
              <li key={1} className="challenge-item">
                <ArrowRight className="icon" />
                <span>
                  With multiple channels and threads, users struggle to maintain
                  context, often jumping between discussions.
                </span>
              </li>
              <li key={2} className="challenge-item">
                <ArrowRight className="icon" />
                <span>
                  Channel clutter and disorganized conversations making it hard
                  to follow important discussions.
                </span>
              </li>
              <li key={3} className="challenge-item">
                <ArrowRight className="icon" />
                <span>
                  Limited external communication capabilities compared to
                  internal use. (Applies to other messaging platforms)
                </span>
              </li>
              <li key={4} className="challenge-item">
                <ArrowRight className="icon" />
                <span>
                  The flood of notifications from various channels can overwhelm
                  users, causing distractions and stress.
                </span>
              </li>
              <li key={5} className="challenge-item">
                <ArrowRight className="icon" />
                <span>Time-consuming management of overflowing inboxes.</span>
              </li>
              <li key={6} className="challenge-item">
                <ArrowRight className="icon" />
                <span>
                  Susceptibility to spam and security risks like phishing
                  attacks.
                </span>
              </li>
            </ul>
          </section>

          <section className="section">
            <h2 className="section-title">How RelayBeam Solves This?</h2>
            <p className="section-text">
              RelayBeam is a Multi-Port Real-Time Contextual Messaging Platform.
              Users can create multiple ports for different contexts and
              purposes of communication. It provides robust real-time messaging
              capabilities, through which users can seamlessly communicate
              across various contexts, simplifying communication and
              collaboration for both teams and individuals. Whether it's
              coordinating with colleagues on a project, organizing social
              events with friends, or staying connected with family, RelayBeam
              streamlines communication and enhances collaboration from within a
              single interface.
            </p>
          </section>

          <section className="section">
            <h2 className="section-title">Working</h2>
            <p className="section-text">
              On RelayBeam, users can create multiple ports for different
              contexts and purposes of communication. Ports serves as an
              endpoint in identifying and sending/receiving messages to users.
            </p>

            <div className="feature-card">
              <h3 className="feature-title">
                <CheckCircle className="icon" />
                Example 1
              </h3>
              <p className="feature-description">
                <ul style={{ paddingLeft: "1.5rem" }}>
                  <li className="example-item">
                    Consider a user ‘Michael’ is a Doctor and creates an account
                    with the username ‘michael’ on RelayBeam.
                  </li>
                  <li className="example-item">
                    If Michael needs to communicate with his patients as well as
                    requires personal messaging, then Michael can create two
                    separate ports with any custom port label.
                  </li>
                  <li className="example-item">
                    If Michael creates ports with label as ‘hospital’ and ‘home’
                    then the respective RelayBeam address for those ports become
                    ‘michael@hospital’ and ‘michael@home’.
                  </li>
                  <li className="example-item" style={{ fontWeight: "bold" }}>
                    {`RelayBeam address is of the form ‘<username>@<port_label>’`}
                  </li>
                  <li className="example-item">
                    As the username is always unique, this guarantees the
                    uniqueness of the RelayBeam address.
                  </li>
                </ul>
              </p>
            </div>

            <div className="feature-card" style={{ marginTop: "25px" }}>
              <h3 className="feature-title">
                <CheckCircle className="icon" />
                Example 2
              </h3>
              <p className="feature-description">
                <ul style={{ paddingLeft: "1.5rem" }}>
                  <li className="example-item">
                    Consider a user ‘John’ is an HR personnel in an
                    organization.
                  </li>
                  <li className="example-item">
                    Now, John might need to communicate for different purposes,
                    for example to collect resumes from multiple candidates for
                    open positions, for internal administrative work, for
                    payroll related work, for banking activities.
                  </li>
                  <li className="example-item">
                    So, here John can create different ports as per the
                    communication context.
                  </li>
                  <li className="example-item">
                    Now, if John creates ports such as ‘resumes’, ‘admin’,
                    ‘payroll’, ‘bank’, then the corresponding RelayBeam
                    addresses become ‘john@resumes’, ‘john@admin’,
                    ‘john@payroll’, ‘john@bank’.
                  </li>
                  <li className="example-item">
                    Now for job related messaging, John can broadcast the
                    ‘john@resumes’ RelayBeam address on LinkedIn, while keeping
                    the other addresses private.
                  </li>
                  <li className="example-item" style={{ fontWeight: "bold" }}>
                    This way messages can be segregated as per its purpose.
                    Thus, RelayBeam provides an alternative to email.
                  </li>
                </ul>
              </p>
            </div>
          </section>

          <section className="section">
            <h2 className="section-title">See It In Action</h2>
            <div className="demo-container">
              <div className="demo-placeholder">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/VehgC3e4egU"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            {/* <p className="section-text">
              Experience firsthand how our platform streamlines your workflow
              and boosts productivity.
            </p> */}
          </section>

          <div
            className="feature-card"
            style={{ marginTop: "25px", borderLeft: "4px solid #2c5282" }}
          >
            <h3 className="feature-title">Why We're Different</h3>
            <p>Port based communication offers:</p>
            <ul className="advantage-list">
              <li>Each port has its own unique identity.</li>
              <li>Synchronous & real-time across various contexts.</li>
              <li>Improved organization and information management.</li>
              <li>Reduced distractions.</li>
              <li>Create new ports from same account.</li>
              <li>
                Single account can be used for both personal as well as
                professional environment.
              </li>
            </ul>
          </div>

          <div className="highlight-note">
            <p>
              RelayBeam is an independent messaging platform and has its own
              messaging capabilities. It is not like it collects/integrates
              messages from different third-party apps/email and shows it to you
              collectively at one place.
            </p>
          </div>

          <section className="section start-using">
            <h2 className="section-title">
              Start using RelayBeam, visit:{" "}
              <Link href="https://relaybeam.com" isExternal>
                relaybeam.com
              </Link>
            </h2>
          </section>

          <section className="section">
            <div className="developer-info">
              <div className="developer-details">
                <h3 className="developer-name">Suprit Gandhi</h3>
                <p className="developer-title">Founder & Developer</p>
                <p className="developer-bio">
                  RelayBeam ID: supritgandhi@relaybeam
                </p>
              </div>
            </div>
            <div className="contact-links">
              <a
                href="https://www.linkedin.com/in/supritgandhi"
                target="_blank"
                rel="noopener noreferrer"
                className="contact-button linkedin-button"
              >
                <Linkedin className="icon" color="white" />
                LinkedIn
              </a>
            </div>
          </section>
        </main>

        <footer className="footer">
          <p>&copy; 2024 Suprit Gandhi. All rights reserved.</p>
        </footer>
      </div>
    </div>
  );
};

export default About;
