import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Floppy2Fill, PencilSquare, Person } from "react-bootstrap-icons";
import { format, formatISO } from "date-fns";
import { DayPicker } from "react-day-picker";
import { useUser } from "../../contexts/UserProvider";
import { useApi } from "../../contexts/ApiProvider";
import EditProfilePic from "./EditProfilePic";

export default function UserDetails() {
  const { user, getUser } = useUser();
  const { api } = useApi();
  const toast = useToast();

  const [editFullName, setEditFullName] = useState(user.full_name || null);
  const [editDateOfBirth, setEditDateOfBirth] = useState("");
  const [dayPickerComp, setDayPickerComp] = useState("");

  const {
    isOpen: isEditFullNameOpen,
    onOpen: onEditFullNameOpen,
    onClose: onEditFullNameClose,
  } = useDisclosure();
  const {
    isOpen: isEditDobOpen,
    onOpen: onEditDobOpen,
    onClose: onEditDobClose,
  } = useDisclosure();

  function handleEditFullNameClose() {
    setEditFullName(user.full_name);
    onEditFullNameClose();
  }

  function handleEditDateOfBirth(dateOfBirth) {
    setEditDateOfBirth(formatISO(dateOfBirth, { representation: "date" }));
    setDayPickerComp(dateOfBirth);
  }

  function handleEditDobClose() {
    setEditDateOfBirth("");
    setDayPickerComp("");
    onEditDobClose();
  }

  function handleEditFullName() {
    api("/api/users/edit-full-name", "PUT", {
      full_name: editFullName,
      username: user.username,
    })
      .then((data) => {
        onEditFullNameClose();
        getUser(user.username);
        toast({
          title: "Name updated successfully",
          status: "success",
          position: "top",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleEditDob() {
    api("/api/users/edit-date-of-birth", "PUT", {
      date_of_birth: editDateOfBirth,
      username: user.username,
    })
      .then((data) => {
        onEditDobClose();
        getUser(user.username);
        toast({
          title: "Date of Birth updated successfully",
          status: "success",
          position: "top",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div style={{ marginBottom: "20px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "30px 0px",
          color: "#0059B3",
          fontWeight: "bold",
        }}
      >
        <Person size={30} />
        <div style={{ margin: "0px 10px", fontSize: "20px" }}>User Details</div>
      </div>

      <EditProfilePic />

      <div style={{ margin: "20px 0px" }}>
        <div style={{ color: "gray" }}>FULL NAME</div>
        <div>
          <Button
            style={{
              border: "1px solid #E3E3E3",
              backgroundColor: "white",
              margin: "10px 0px",
            }}
            onClick={onEditFullNameOpen}
            rightIcon={
              <PencilSquare size={20} style={{ marginLeft: "15px" }} />
            }
          >
            {user.full_name}
          </Button>
        </div>
      </div>

      <Modal isOpen={isEditFullNameOpen} onClose={handleEditFullNameClose}>
        <ModalOverlay />
        <ModalContent style={{ borderRadius: "15px" }}>
          <ModalHeader>Edit Full Name</ModalHeader>
          <ModalCloseButton />
          <ModalBody style={{ paddingBottom: "6px" }}>
            <FormControl>
              <FormLabel>Full Name</FormLabel>
              <div>
                <div>
                  <Input
                    placeholder="Enter Full Name"
                    value={editFullName}
                    onChange={(e) => setEditFullName(e.currentTarget.value)}
                  />
                </div>
              </div>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="outline"
              style={{ margin: "7px 10px" }}
              onClick={() => handleEditFullNameClose()}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleEditFullName()}
              style={{
                backgroundColor: "#0059B3",
                color: "white",
                margin: "7px 10px",
              }}
              rightIcon={<Floppy2Fill style={{ marginBottom: "2px" }} />}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <div style={{ margin: "20px 0px" }}>
        <div style={{ color: "gray", marginTop: "15px" }}>
          DATE OF BIRTH {"(YYYY-MM-DD)"}
        </div>
        <div>
          <Button
            style={{
              border: "1px solid #E3E3E3",
              backgroundColor: "white",
              margin: "10px 0px",
            }}
            onClick={onEditDobOpen}
            rightIcon={
              <PencilSquare size={20} style={{ marginLeft: "15px" }} />
            }
          >
            {user.date_of_birth}
          </Button>
        </div>
      </div>

      <Modal isOpen={isEditDobOpen} onClose={handleEditDobClose}>
        <ModalOverlay />
        <ModalContent style={{ borderRadius: "15px" }}>
          <ModalHeader>Edit Date of Birth</ModalHeader>
          <ModalCloseButton />
          <ModalBody style={{ paddingBottom: "6px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <DayPicker
                mode="single"
                captionLayout="dropdown-buttons"
                fromYear={1900}
                toYear={2024}
                required
                selected={dayPickerComp}
                onSelect={handleEditDateOfBirth}
              />
            </div>
            {editDateOfBirth !== "" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#FF6200",
                  fontWeight: "bold",
                }}
              >
                {format(dayPickerComp, "PP")}
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              variant="outline"
              style={{ margin: "7px 10px" }}
              onClick={() => handleEditDobClose()}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleEditDob()}
              style={{
                backgroundColor: "#0059B3",
                color: "white",
                margin: "7px 10px",
              }}
              rightIcon={<Floppy2Fill style={{ marginBottom: "2px" }} />}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}
