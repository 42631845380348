import React, { useEffect } from "react";
import { useUser } from "../contexts/UserProvider";
import { silentRefresh } from "../utils/silent-refresh";
import { parseJwt } from "../utils/jwt-parser";
import { useAuth } from "../contexts/AuthProvider";
import { SocketProvider } from "../contexts/SocketProvider";
import HomeWrapper from "./HomeWrapper";
import UserProfileInactive from "./UserProfileInactive";
import Loader from "./loader/Loader";
import InitialView from "./InitialView";
import { FileProvider } from "../contexts/FileProvider";
import { Route, Routes, Navigate } from "react-router-dom";
import Register from "./Register";

export default function Wrapper() {
  const { token, setToken } = useAuth();
  const {
    user,
    setUser,
    getUser,
    userInboxes,
    getUserInboxes,
    userLoading,
    userInboxesLoading,
  } = useUser();

  useEffect(() => {
    silentRefresh()
      .then((data) => {
        setToken(data.accessToken);
        if (data.accessToken) {
          const parsedJwt = parseJwt(data.accessToken);
          getUser(parsedJwt.username);
          getUserInboxes(parsedJwt.username);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  function handleLogout() {
    fetch(process.env.REACT_APP_API_URL + "/api/auth/refresh_token", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error(`HTTP Error: Status ${response.status}`);
      })
      .then((data) => {
        setToken(null);
        setUser(null);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (userLoading || userInboxesLoading) {
    return <Loader />;
  }

  return (
    <div style={{ height: "100%" }}>
      <Routes>
        <Route
          path="/"
          element={
            token && user ? (
              userInboxes && user.active ? (
                <SocketProvider user={user} userInboxes={userInboxes}>
                  <FileProvider>
                    <HomeWrapper handleLogout={handleLogout} />
                  </FileProvider>
                </SocketProvider>
              ) : (
                <UserProfileInactive handleLogout={handleLogout} />
              )
            ) : (
              <InitialView />
            )
          }
        />
        <Route
          path="/register"
          element={token ? <Navigate to="/" replace /> : <Register />}
        />
        <Route path="/*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}
