import React, { useEffect } from "react";
import "./App.css";

import { ChakraProvider } from "@chakra-ui/react";
import { AuthProvider } from "./contexts/AuthProvider";
import { UserProvider } from "./contexts/UserProvider";
import Wrapper from "./components/Wrapper";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ApiProvider } from "./contexts/ApiProvider";
import TermsOfService from "./components/terms-policy/TermsOfService";
import PrivacyPolicy from "./components/terms-policy/PrivacyPolicy";
import { UsersInfoProvider } from "./contexts/UsersInfoProvider";
import { ConsolesRemover } from "./utils/consoles-remover";
import About from "./components/about/About";

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      ConsolesRemover();
    }
  }, []);

  return (
    <ChakraProvider>
      <div className="App">
        <AuthProvider>
          <ApiProvider>
            <UsersInfoProvider>
              <UserProvider>
                <Router>
                  <Routes>
                    <Route exact path="/*" element={<Wrapper />} />
                    <Route path="/terms" element={<TermsOfService />} />
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    <Route path="/about" element={<About />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
                </Router>
              </UserProvider>
            </UsersInfoProvider>
          </ApiProvider>
        </AuthProvider>
      </div>
    </ChakraProvider>
  );
}

export default App;
