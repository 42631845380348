import React from "react";
import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Show,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import InboxList from "./InboxList";
import Messages from "./Messages";
import Threads from "./Threads";
import ThreadTopic from "./ThreadTopic";
import "./Contents.css";
import Notifications from "./Notifications";
import ThreadParticipants from "./ThreadParticipants";
import AttachmentsList from "./AttachmentsList";
import MessageModeSwitch from "./MessageModeSwitch";
import DirectConversations from "./direct-conversations/DirectConversations";
import DirectConversationMessages from "./direct-conversations/DirectConversationMessages";
import { PeopleFill, XLg } from "react-bootstrap-icons";

export default function Contents({
  handleInbox,
  selectedInbox,
  handleThread,
  selectedThread,
  handleDirectConversation,
  selectedDirectConversation,
  messageMode,
  handleMessageMode,
  navSize,
  handleView,
  handleLogout,
}) {
  const {
    isOpen: isThreadsOpen,
    onOpen: onThreadsOpen,
    onClose: onThreadsClose,
  } = useDisclosure();
  const {
    isOpen: isDirectConversationsOpen,
    onOpen: onDirectConversationsOpen,
    onClose: onDirectConversationsClose,
  } = useDisclosure();
  const {
    isOpen: isParticipantsOpen,
    onOpen: onParticipantsOpen,
    onClose: onParticipantsClose,
  } = useDisclosure();

  function handleMessageModalClose() {
    handleThread(null);
    onThreadsClose();
  }

  function handleDirectConversationModalClose() {
    handleDirectConversation(null);
    onDirectConversationsClose();
  }

  return (
    <div className="contents-container">
      <div className="contents-top">
        <div className="inbox-list">
          <InboxList
            handleInbox={handleInbox}
            selectedInbox={selectedInbox}
            navSize={navSize}
          />
        </div>
        <div className="notifications">
          <Notifications handleView={handleView} handleLogout={handleLogout} />
        </div>
      </div>
      <div className="contents-box">
        {selectedInbox !== null && (
          <div className="contents-box-left">
            <div className="message-mode-switch">
              <MessageModeSwitch
                messageMode={messageMode}
                handleMessageMode={handleMessageMode}
                selectedInbox={selectedInbox}
              />
            </div>
            {messageMode === "multi" && (
              <div className="threads-container">
                <Threads
                  selectedInbox={selectedInbox}
                  handleThread={handleThread}
                  selectedThread={selectedThread}
                />
              </div>
            )}
            {messageMode === "single" && (
              <div className="direct-conversations-container">
                <DirectConversations
                  selectedInbox={selectedInbox}
                  handleDirectConversation={handleDirectConversation}
                  selectedDirectConversation={selectedDirectConversation}
                />
              </div>
            )}
          </div>
        )}
        <div className="contents-box-center">
          {messageMode === "multi" && (
            <div>
              {selectedThread && (
                <div
                  style={{
                    flex: "1 0 auto",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="contents-thread-topic-container">
                    <ThreadTopic threadTopic={selectedThread.thread_topic} />
                  </div>
                  <Messages
                    selectedThread={selectedThread}
                    selectedInbox={selectedInbox}
                    key={selectedThread.thread_id}
                    messageMode={messageMode}
                  />
                </div>
              )}
            </div>
          )}
          {messageMode === "single" && (
            <div>
              {selectedDirectConversation && (
                <div
                  style={{
                    flex: "1 0 auto",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="contents-thread-topic-container">
                    <ThreadTopic
                      threadTopic={
                        selectedDirectConversation.conversation_recipient
                      }
                    />
                  </div>
                  <DirectConversationMessages
                    selectedDirectConversation={selectedDirectConversation}
                    selectedInbox={selectedInbox}
                    key={selectedDirectConversation.direct_conversation_id}
                    messageMode={messageMode}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <Show breakpoint="(max-width: 1024px)">
          <div>
            {selectedThread && (
              <Modal
                onClose={() => handleMessageModalClose()}
                size={"full"}
                isOpen={selectedThread}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalBody>
                    <div
                      style={{
                        flex: "1 0 auto",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "2px",
                        }}
                        className="contents-thread-topic-container"
                      >
                        <ThreadTopic
                          threadTopic={selectedThread.thread_topic}
                        />
                        <div>
                          <IconButton
                            icon={<PeopleFill color="#0059B3" />}
                            onClick={onParticipantsOpen}
                            style={{ margin: "20px 5px 20px 0px" }}
                          />
                          <IconButton
                            icon={<XLg />}
                            onClick={() => handleMessageModalClose()}
                            style={{ margin: "20px 0px 20px 5px" }}
                          />
                        </div>
                      </div>
                      <Modal
                        onClose={onParticipantsClose}
                        isOpen={isParticipantsOpen}
                        scrollBehavior="inside"
                        isCentered={true}
                        size="xl"
                      >
                        <ModalOverlay />
                        <ModalContent
                          style={{
                            borderRadius: "20px",
                            margin: "10px",
                          }}
                        >
                          <ModalHeader style={{ color: "#0059B3" }}>
                            Participants
                          </ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            {selectedThread.thread_participants && (
                              <ThreadParticipants
                                threadId={selectedThread.thread_id}
                                threadParticipants={
                                  selectedThread.thread_participants
                                }
                              />
                            )}
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              colorScheme="blue"
                              mr={3}
                              onClick={onParticipantsClose}
                            >
                              Close
                            </Button>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                      <Messages
                        selectedThread={selectedThread}
                        selectedInbox={selectedInbox}
                        key={selectedThread.thread_id}
                        messageMode={messageMode}
                      />
                    </div>
                  </ModalBody>
                </ModalContent>
              </Modal>
            )}
          </div>
        </Show>
        <Show breakpoint="(max-width: 1024px)">
          <div>
            {selectedDirectConversation && (
              <Modal
                onClose={() => handleDirectConversationModalClose()}
                size={"full"}
                isOpen={selectedDirectConversation}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalBody>
                    <div
                      style={{
                        flex: "1 0 auto",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "2px",
                        }}
                        className="contents-thread-topic-container"
                      >
                        <ThreadTopic
                          threadTopic={
                            selectedDirectConversation.conversation_recipient
                          }
                        />
                        <IconButton
                          icon={<XLg />}
                          onClick={() => handleDirectConversationModalClose()}
                          style={{ margin: "20px 0px 20px 5px" }}
                        />
                      </div>
                    </div>
                    <DirectConversationMessages
                      selectedDirectConversation={selectedDirectConversation}
                      selectedInbox={selectedInbox}
                      key={selectedDirectConversation.direct_conversation_id}
                      messageMode={messageMode}
                    />
                  </ModalBody>
                </ModalContent>
              </Modal>
            )}
          </div>
        </Show>

        {messageMode === "multi" && selectedThread && (
          <div className="contents-box-right">
            <Tabs isFitted isLazy>
              <TabList>
                <Tab>Participants</Tab>
                <Tab>Attachments</Tab>
              </TabList>

              <TabPanels className="tab-panels">
                <TabPanel>
                  {selectedThread.thread_participants && (
                    <ThreadParticipants
                      threadId={selectedThread.thread_id}
                      threadParticipants={selectedThread.thread_participants}
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  <AttachmentsList
                    userInbox={selectedInbox}
                    threadId={selectedThread.thread_id}
                    messageMode={messageMode}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>
        )}
        {messageMode === "single" && selectedDirectConversation && (
          <div className="contents-box-right">
            <Tabs isFitted isLazy>
              <TabList>
                <Tab>Attachments</Tab>
              </TabList>

              <TabPanels className="tab-panels">
                <TabPanel>
                  <AttachmentsList
                    userInbox={selectedInbox}
                    directConversationId={
                      selectedDirectConversation.direct_conversation_id
                    }
                    messageMode={messageMode}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>
        )}
      </div>
    </div>
  );
}
