import React, { useCallback, useEffect, useRef, useState } from "react";
import "./DirectConversationMessages.css";
import { Avatar, Divider, IconButton, SlideFade } from "@chakra-ui/react";
import DirectMessageController from "./DirectMessageController";
import { useData } from "../../contexts/DataProvider";
import { useApi } from "../../contexts/ApiProvider";
import { useSocket } from "../../contexts/SocketProvider";
import {
  checkIfDatesAreEqual,
  dateParser,
  timeParser,
} from "../../utils/date-time-parser";
import { CloudDownload } from "react-bootstrap-icons";
import { fileSizeParser } from "../../utils/file-size-parser";
import FileTypeIcon from "../FileTypeIcon";
import { useFile } from "../../contexts/FileProvider";
import { useUsersInfo } from "../../contexts/UsersInfoProvider";

export default function DirectConversationMessages({
  selectedDirectConversation,
  selectedInbox,
  messageMode,
}) {
  const {
    directConversationMessages,
    sendDirectConversationMessage,
    getDirectConversationMessages,
    isDirectConversationMessagesPageEnd,
  } = useData();
  const { api } = useApi();
  const socket = useSocket();
  const { downloadFile } = useFile();
  const { checkUserByInbox, usersInfo } = useUsersInfo();

  const [scrollIndex, setScrollIndex] = useState(0);

  const lastMessageRef = useRef(null);

  useEffect(() => {
    lastMessageRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    checkUserByInbox(selectedDirectConversation.conversation_recipient);
  }, [selectedDirectConversation.direct_conversation_id]);

  const setLastMessageRef = useCallback(
    (node) => {
      if (node) {
        node.scrollIntoView();
      }
    },
    [directConversationMessages]
  );

  function sendDirectMessage(messageText) {
    api("/api/direct-messages/send", "POST", {
      message: messageText,
      sender: selectedInbox,
      recipient: selectedDirectConversation.conversation_recipient,
      direct_conversation_id: selectedDirectConversation.direct_conversation_id,
    })
      .then((data) => {
        socket.emit("send-direct-message", {
          directConversationId:
            selectedDirectConversation.direct_conversation_id,
          sender: selectedInbox,
          recipient: selectedDirectConversation.conversation_recipient,
          message: messageText,
          messageId: data.messageId,
          hasAttachment: false,
          attachmentObj: {},
        });

        sendDirectConversationMessage({
          directConversationId:
            selectedDirectConversation.direct_conversation_id,
          conversationRecipient:
            selectedDirectConversation.conversation_recipient,
          userInbox: selectedInbox,
          recipient: selectedDirectConversation.conversation_recipient,
          message: messageText,
          messageId: data.messageId,
          sender: selectedInbox,
          hasAttachment: false,
          attachmentObj: {},
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleScroll(event) {
    if (
      event.currentTarget.scrollTop === 0 &&
      !isDirectConversationMessagesPageEnd[
        selectedDirectConversation.direct_conversation_id
      ]
    ) {
      setScrollIndex(
        directConversationMessages[
          selectedDirectConversation.direct_conversation_id
        ].length - 1
      );
      getDirectConversationMessages(
        selectedInbox,
        selectedDirectConversation.direct_conversation_id
      );
    }
  }

  return (
    <div className="direct-messages">
      <div
        style={{ flex: "1 0 auto", display: "flex", flexDirection: "column" }}
      >
        <div className="messages-container" onScroll={handleScroll}>
          {directConversationMessages &&
            directConversationMessages[
              selectedDirectConversation.direct_conversation_id
            ]
              ?.slice(0)
              .reverse()
              .map((directMessage, index, msgArr) => {
                const lastMessage =
                  directConversationMessages[
                    selectedDirectConversation.direct_conversation_id
                  ].length -
                    1 -
                    scrollIndex ===
                  index;

                const newMessages =
                  index > 0
                    ? msgArr[index - 1].message_id ===
                      selectedDirectConversation.last_read_message_id
                    : false;

                const directMessageSenderUsername =
                  directMessage?.sender?.substring(
                    0,
                    directMessage?.sender?.indexOf("@")
                  );

                return (
                  <SlideFade in={true}>
                    <div ref={lastMessage ? setLastMessageRef : null}>
                      {newMessages && (
                        <div
                          className="new-messages-divider"
                          style={
                            index === 0 ? { margin: "20px 10px 0px 10px" } : {}
                          }
                        >
                          <Divider
                            style={{
                              borderColor: "#C0C0C0",
                              borderStyle: "dashed",
                              borderWidth: "1px",
                              marginTop: "10px",
                            }}
                          />
                          <div
                            style={{
                              padding: "0px 10px",
                              fontSize: "15px",
                              color: "#FF6666",
                              fontWeight: "bold",
                              flex: "1 0 auto",
                            }}
                          >
                            New Messages
                          </div>
                          <Divider
                            style={{
                              borderColor: "#C0C0C0",
                              borderStyle: "dashed",
                              marginTop: "10px",
                            }}
                          />
                        </div>
                      )}
                      {((index > 0 &&
                        !checkIfDatesAreEqual(
                          directMessage.timestamp,
                          msgArr[index - 1].timestamp
                        )) ||
                        index === 0) && (
                        <div
                          className="messages-date-divider"
                          style={
                            index === 0 ? { margin: "20px 10px 0px 10px" } : {}
                          }
                        >
                          <Divider
                            style={{
                              borderColor: "#C0C0C0",
                              borderWidth: "1px",
                              marginTop: "10px",
                            }}
                          />
                          <div
                            style={{
                              padding: "0px 10px",
                              fontSize: "15px",
                              color: "#0059B3",
                              fontWeight: "bold",
                              flex: "1 0 auto",
                            }}
                          >
                            {dateParser(directMessage.timestamp)}
                          </div>
                          <Divider
                            style={{
                              borderColor: "#C0C0C0",
                              borderWidth: "1px",
                              marginTop: "10px",
                            }}
                          />
                        </div>
                      )}
                      {index > 0 &&
                        checkIfDatesAreEqual(
                          directMessage.timestamp,
                          msgArr[index - 1].timestamp
                        ) &&
                        directMessage.sender !== msgArr[index - 1].sender && (
                          <div style={{ padding: "0px 10px", margin: "10px" }}>
                            <Divider style={{ borderColor: "#E3E3E3" }} />
                          </div>
                        )}
                      <div
                        key={index}
                        style={{
                          padding:
                            (index > 0 &&
                              directMessage.sender !==
                                msgArr[index - 1].sender) ||
                            index === 0
                              ? "0px 10px"
                              : "0px 20px",
                          margin:
                            (index > 0 &&
                              directMessage.sender !==
                                msgArr[index - 1].sender) ||
                            index === 0
                              ? "0px 10px"
                              : "0px",
                          display: "flex",
                        }}
                      >
                        {((index > 0 &&
                          directMessage.sender !== msgArr[index - 1].sender) ||
                          index === 0 ||
                          newMessages ||
                          !checkIfDatesAreEqual(
                            directMessage.timestamp,
                            msgArr[index - 1].timestamp
                          )) && (
                          <div style={{ marginTop: "15px" }}>
                            <Avatar
                              size="sm"
                              src={
                                usersInfo[directMessageSenderUsername]
                                  ?.profilePictureV1
                              }
                            />
                          </div>
                        )}
                        <div
                          className={
                            (index > 0 &&
                              directMessage.sender !==
                                msgArr[index - 1].sender) ||
                            index === 0 ||
                            newMessages ||
                            !checkIfDatesAreEqual(
                              directMessage.timestamp,
                              msgArr[index - 1].timestamp
                            )
                              ? "first-message-text"
                              : "other-messages-text"
                          }
                        >
                          {((index > 0 &&
                            directMessage.sender !==
                              msgArr[index - 1].sender) ||
                            index === 0 ||
                            newMessages ||
                            !checkIfDatesAreEqual(
                              directMessage.timestamp,
                              msgArr[index - 1].timestamp
                            )) && (
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "16px",
                                marginTop: "10px",
                              }}
                            >
                              {directMessage.sender}
                            </div>
                          )}
                          {(directMessage.message ||
                            directMessage.has_attachment) && (
                            <div>
                              {directMessage.message && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                  className={
                                    (index > 0 &&
                                      directMessage.sender !==
                                        msgArr[index - 1].sender) ||
                                    index === 0 ||
                                    newMessages ||
                                    !checkIfDatesAreEqual(
                                      directMessage.timestamp,
                                      msgArr[index - 1].timestamp
                                    )
                                      ? "first-message-of-section"
                                      : "other-messages-of-section"
                                  }
                                >
                                  <div>
                                    <div className="message-content">
                                      {directMessage.message}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "13px",
                                      color: "#909090",
                                    }}
                                  >
                                    {timeParser(directMessage.timestamp)}
                                  </div>
                                </div>
                              )}
                              <div
                                className={
                                  (index > 0 &&
                                    directMessage.sender !==
                                      msgArr[index - 1].sender) ||
                                  index === 0 ||
                                  newMessages ||
                                  !checkIfDatesAreEqual(
                                    directMessage.timestamp,
                                    msgArr[index - 1].timestamp
                                  )
                                    ? "first-message-of-section"
                                    : "other-messages-of-section"
                                }
                              >
                                {directMessage.has_attachment && (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div className="attachment-container">
                                      <div className="attachment">
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <FileTypeIcon
                                            fileType={
                                              directMessage.attachment_mimetype
                                            }
                                            size={30}
                                            color="#0059B3"
                                          />
                                        </div>
                                        <div className="attachment-details">
                                          <div>
                                            {directMessage.attachment_name}
                                          </div>
                                          <div className="attachment-size">
                                            {fileSizeParser(
                                              Number(
                                                directMessage.attachment_size
                                              )
                                            )}
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <IconButton
                                            icon={
                                              <CloudDownload color="#0059B3" />
                                            }
                                            onClick={() =>
                                              downloadFile(
                                                directMessage.attachment_key,
                                                directMessage.attachment_name
                                              )
                                            }
                                            variant={"outline"}
                                            isRound={true}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "13px",
                                        color: "#909090",
                                      }}
                                    >
                                      {!directMessage.message && (
                                        <div>
                                          {timeParser(directMessage.timestamp)}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </SlideFade>
                );
              })}
        </div>
        <DirectMessageController
          sendDirectMessage={sendDirectMessage}
          selectedDirectConversation={selectedDirectConversation}
          selectedInbox={selectedInbox}
          messageMode={messageMode}
        />
      </div>
    </div>
  );
}
