import React, { useState } from "react";
import { ReactComponent as RelayBeam } from "../../graphics/RelayBeam.svg";
import { ReactComponent as RelayBeamLogo } from "../../graphics/RelayBeam_Logo.svg";
import "./Sidebar.css";
import {
  Button,
  Divider,
  useDisclosure,
  Flex,
  IconButton,
  Show,
  Tooltip,
  Badge,
} from "@chakra-ui/react";
import NavElement from "./sidebar/NavElement";
import {
  BoxArrowLeft,
  ChatLeftText,
  Folder,
  Gear,
  List,
  Pen,
} from "react-bootstrap-icons";
import Compose from "../compose/Compose";
import { useSocket } from "../../contexts/SocketProvider";

export default function Sidebar({
  selectedInbox,
  handleThread,
  handleDirectConversation,
  view,
  handleView,
  messageMode,
  handleLogout,
  navSize,
  setNavSize,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const socket = useSocket();
  const [isSocketOnline, setIsSocketOnline] = useState(false);

  socket.on("connect", () => {
    setIsSocketOnline(true);
  });

  socket.on("disconnect", () => {
    setIsSocketOnline(false);
  });

  return (
    <Flex
      pos="sticky"
      border="1px solid #E3E3E3"
      w={navSize === "large" ? "200px" : "75px"}
      flexDir="column"
      justifyContent="space-between"
      backgroundColor={"#FFFFFF"}
      h="100vh"
    >
      <Flex
        p="5%"
        flexDir="column"
        w="100%"
        alignItems={navSize === "large" ? "flex-start" : "center"}
        as="nav"
      >
        {navSize === "large" && <RelayBeam style={{ marginTop: "10px" }} />}
        {navSize !== "large" && (
          <div style={{ width: "60px" }}>
            <RelayBeamLogo style={{ margin: "10px 5px 0px 5px" }} />
          </div>
        )}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: navSize === "large" ? "flex-end" : "center",
            color: "#505050",
            fontSize: "12px",
            marginTop: navSize === "small" && "7px",
          }}
        >
          <Badge>BETA</Badge>
        </div>
        <Show breakpoint="(min-width: 1025px)">
          <IconButton
            mt={5}
            icon={<List />}
            onClick={() => {
              if (navSize === "large") setNavSize("small");
              else setNavSize("large");
            }}
          />
        </Show>

        <Tooltip
          label="Please select a port"
          hasArrow
          placement="right"
          bg="gray.300"
          color="black"
          isDisabled={selectedInbox}
        >
          <div className="compose-button-container">
            {navSize === "large" && (
              <Button
                leftIcon={<Pen />}
                onClick={onOpen}
                isDisabled={!selectedInbox}
                style={{
                  height: "50px",
                  width: "100%",
                  backgroundColor: "#FF6200",
                  color: "white",
                }}
                className="compose-button"
              >
                Compose
              </Button>
            )}
            {navSize === "small" && (
              <IconButton
                icon={<Pen />}
                onClick={onOpen}
                isDisabled={!selectedInbox}
                style={{
                  height: "50px",
                  backgroundColor: "#FF6200",
                  color: "white",
                  width: "75%",
                }}
                className="compose-button"
              />
            )}
          </div>
        </Tooltip>

        <Compose
          selectedInbox={selectedInbox}
          handleThread={handleThread}
          handleDirectConversation={handleDirectConversation}
          messageMode={messageMode}
          isOpen={isOpen}
          onClose={onClose}
        />
        <div style={{ width: "100%", marginTop: "20px" }}>
          <NavElement
            icon={ChatLeftText}
            title="Messages"
            handleView={handleView}
            view="messages"
            active={view === "messages"}
            navSize={navSize}
          />
          <NavElement
            icon={Folder}
            title="Files"
            handleView={handleView}
            view="files"
            active={view === "files"}
            navSize={navSize}
          />
          <NavElement
            icon={Gear}
            title="Settings"
            handleView={handleView}
            view="settings"
            active={view === "settings"}
            navSize={navSize}
          />
        </div>
      </Flex>

      {navSize === "large" && (
        <Flex
          p="5%"
          flexDir="column"
          w="100%"
          alignItems={navSize === "large" ? "flex-start" : "center"}
          mb={4}
        >
          {navSize === "large" && (
            <Flex flexDir="column" w="100%">
              <Flex mb={4} w="100%">
                <Flex
                  flexDir="column"
                  display={navSize === "large" ? "flex" : "none"}
                  w="100%"
                >
                  {isSocketOnline ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "0px 15px",
                        padding: "10px 20px",
                        backgroundColor: "#F0F0FF",
                        borderRadius: "9px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <div className="online-indicator" />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginTop: "5px",
                          marginRight: "5px",
                        }}
                      >
                        <div>Online</div>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "0px 15px",
                        padding: "10px 20px",
                        backgroundColor: "#F0F0FF",
                        borderRadius: "9px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <div className="offline-indicator" />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginTop: "5px",
                          marginRight: "5px",
                        }}
                      >
                        <div>Offline</div>
                      </div>
                    </div>
                  )}
                </Flex>
              </Flex>
            </Flex>
          )}
          <Divider
            display={navSize === "large" ? "flex" : "none"}
            style={{ borderColor: "#C0C0C0" }}
          />
          <Flex mt={4} align="center" w="100%">
            <Flex
              flexDir="column"
              display={navSize === "large" ? "flex" : "none"}
              w="100%"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                  padding: "0px 10px",
                }}
              >
                <Button
                  leftIcon={<BoxArrowLeft size={25} />}
                  style={{
                    width: "100%",
                    height: "45px",
                    borderRadius: "9px",
                  }}
                  variant="outline"
                  onClick={() => handleLogout()}
                >
                  Logout
                </Button>
              </div>
            </Flex>
          </Flex>
        </Flex>
      )}

      {navSize === "small" && (
        <Flex p="5%" flexDir="column" w="100%" alignItems={"center"} mb={4}>
          <Flex mt={4} align="center" w="100%">
            <div
              onClick={() => handleLogout()}
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <BoxArrowLeft size={25} />
            </div>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}
