import React, { useRef, useState } from "react";
import { Avatar, Button, Input } from "@chakra-ui/react";
import { useUser } from "../contexts/UserProvider";
import { useApi } from "../contexts/ApiProvider";
import { randomAlphaNumeric } from "../utils/utils";
import imageCompression from "browser-image-compression";
import Loader from "./loader/Loader";
import { useUsersInfo } from "../contexts/UsersInfoProvider";

export default function UserProfilePic({ handleNextPage }) {
  const [image, setImage] = useState(null);
  const [compressedImage1, setCompressedImage1] = useState(null);
  const [compressedImage2, setCompressedImage2] = useState(null);
  const [isImage1Loading, setIsImage1Loading] = useState(false);
  const [isImage2Loading, setIsImage2Loading] = useState(false);

  const { user, getUser, getUserInboxes } = useUser();
  const { getUserInfo } = useUsersInfo();
  const { api } = useApi();

  const fileInputRef = useRef();

  function handleUploadButtonClick() {
    fileInputRef.current.click();
  }

  function handleFileInputChange(e) {
    if (e.target.files && e.target.files[0]) {
      const uploadedImage = e.target.files[0];
      setImage(uploadedImage);

      compressImage1(uploadedImage);
      compressImage2(uploadedImage);
    }
  }

  function handleContinue() {
    const fileExt = image.name.split(".").pop();
    const randomKey = randomAlphaNumeric(8);

    const objName = user.username + "_" + randomKey + "." + fileExt;

    const objKey1 = `users/profile/picture/s1/${objName}`;
    const objKey2 = `users/profile/picture/s2/${objName}`;

    api("/api/users/upload-profile-pic", "POST", {
      objKey1,
      objKey2,
      objName: objName,
      username: user.username,
    })
      .then(async (data) => {
        setIsImage1Loading(true);
        setIsImage2Loading(true);
        await fetch(data.preSignedUrl1, {
          method: "PUT",
          body: compressedImage1,
          mode: "cors",
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("File upload failed");
            }
          })
          .catch((error) => {
            console.error("Error uploading file");
          })
          .finally(() => {
            setIsImage1Loading(false);
          });
        await fetch(data.preSignedUrl2, {
          method: "PUT",
          body: compressedImage2,
          mode: "cors",
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("File upload failed");
            }
          })
          .catch((error) => {
            console.error("Error uploading file");
          })
          .finally(() => {
            setIsImage2Loading(false);
          });
      })
      .catch((error) => {
        console.error("Error uploading file");
      })
      .finally(() => {
        getUser(user.username);
        getUserInfo(user.username);
        getUserInboxes(user.username);
      });
  }

  function handleSkip() {
    getUser(user.username);
    getUserInfo(user.username);
    getUserInboxes(user.username);
  }

  async function compressImage1(file) {
    const options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1024,
      useWebWorker: false,
    };

    const outputImage = await imageCompression(file, options);
    setCompressedImage1(outputImage);
  }

  async function compressImage2(file) {
    const options = {
      maxSizeMB: 0.3,
      maxWidthOrHeight: 1024,
      useWebWorker: false,
    };

    const outputImage = await imageCompression(file, options);
    setCompressedImage2(outputImage);
  }

  if (isImage1Loading || isImage2Loading) {
    return <Loader />;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        padding: "20px",
      }}
    >
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ fontSize: "30px", color: "gray" }}>
          Add Profile Picture (Optional)
        </div>
        <div
          style={{
            margin: "20px",
            width: "200px",
            minHeight: "200px",
            maxHeight: "auto",
            flex: "1 0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!image && <Avatar size="2xl" />}
          {image && <Avatar size="2xl" src={URL.createObjectURL(image)} />}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "300px",
          }}
        >
          <Input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="upload-button"
            ref={fileInputRef}
            onChange={handleFileInputChange}
          />
          {image && (
            <Button
              style={{
                backgroundColor: "#0059B3",
                color: "white",
                width: "100%",
              }}
              mt={4}
              isLoading={!(compressedImage1 && compressedImage2)}
              onClick={() => handleContinue()}
            >
              Continue
            </Button>
          )}
          <label htmlFor="upload-button">
            <Button
              style={
                image
                  ? { width: "100%" }
                  : {
                      backgroundColor: "#0059B3",
                      color: "white",
                      width: "100%",
                    }
              }
              mt={4}
              onClick={() => handleUploadButtonClick()}
            >
              {image ? "Upload other picture" : "Upload"}
            </Button>
          </label>

          <Button mt={4} onClick={() => handleSkip()}>
            Skip
          </Button>
        </div>
      </div>
    </div>
  );
}
