import React, { useState } from "react";
import {
  Button,
  FormControl,
  Input,
  FormErrorMessage,
  useToast,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import "./ChangePassword.css";
import { useUser } from "../../contexts/UserProvider";
import { passwordValidator } from "../../utils/utils";
import { useApi } from "../../contexts/ApiProvider";
import { Key } from "react-bootstrap-icons";

export default function ChangePassword({ handleLogout }) {
  const toast = useToast();

  const [changePassword, setChangePassword] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [newPasswordValidity, setNewPasswordValidity] = useState(false);

  const { user } = useUser();
  const { api } = useApi();

  function handleNewPassword(newPassword) {
    const isNewPasswordValid = passwordValidator(newPassword);
    if (isNewPasswordValid) {
      setNewPasswordValidity(true);
    } else {
      setNewPasswordValidity(false);
    }
    setNewPassword(newPassword);
  }

  function handleChangePassword() {
    if (!changePassword) {
      setChangePassword(true);
    } else if (changePassword) {
      if (newPassword === confirmNewPassword) {
        api("/api/users/change-password", "POST", {
          username: user.username,
          currentPassword,
          newPassword,
          confirmNewPassword,
        })
          .then((data) => {
            if (data.passwordUpdated) {
              toast({
                title: "Password updated successfully",
                description: "Please, login again with the new password",
                status: "success",
                position: "top",
                duration: 9000,
                isClosable: true,
              });

              //LOGOUT
              handleLogout();
            } else {
              toast({
                title: "Error",
                description: data.error,
                status: "error",
                position: "top",
                duration: 9000,
                isClosable: true,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "20px 0px",
          color: "#0059B3",
          fontWeight: "bold",
        }}
      >
        <Key size={30} />
        <div style={{ margin: "0px 10px", fontSize: "20px" }}>
          Password Management
        </div>
      </div>
      {changePassword && (
        <div style={{ maxWidth: "500px", marginTop: "20px" }}>
          <div className="password-input-section">
            <FormControl>
              <FormLabel>Current Password</FormLabel>
              <Input
                type="password"
                onChange={(e) => setCurrentPassword(e.currentTarget.value)}
                placeholder="Enter Current Password"
                className="password-input"
              />
            </FormControl>
          </div>

          <div className="password-input-section">
            <FormControl
              isInvalid={newPassword.length > 0 && !newPasswordValidity}
            >
              <FormLabel>New Password</FormLabel>
              <Input
                type="password"
                onChange={(e) => handleNewPassword(e.currentTarget.value)}
                placeholder="Enter New Password"
                className="password-input"
              />
              <FormErrorMessage>
                Password should be at least 8 characters long
              </FormErrorMessage>
            </FormControl>
          </div>

          <div className="password-input-section">
            <FormControl
              isInvalid={
                confirmNewPassword !== "" && newPassword !== confirmNewPassword
              }
            >
              <FormLabel>Confirm New Password</FormLabel>
              <Input
                type="password"
                onChange={(e) => setConfirmNewPassword(e.currentTarget.value)}
                placeholder="Re-Enter New Password"
                className="password-input"
              />
              {confirmNewPassword !== "" &&
                newPassword !== confirmNewPassword && (
                  <FormErrorMessage>Passwords should match</FormErrorMessage>
                )}
              {confirmNewPassword !== "" &&
                newPassword === confirmNewPassword &&
                newPasswordValidity && (
                  <FormHelperText style={{ color: "green" }}>
                    Passwords match
                  </FormHelperText>
                )}
            </FormControl>
          </div>
        </div>
      )}

      <Button
        style={{
          padding: "20px 25px",
          letterSpacing: "1px",
          margin: "20px 0px",
          fontSize: "15px",
        }}
        isDisabled={
          changePassword &&
          (currentPassword === "" ||
            newPassword === "" ||
            confirmNewPassword === "" ||
            newPassword !== confirmNewPassword ||
            !newPasswordValidity)
        }
        onClick={() => handleChangePassword()}
      >
        CHANGE PASSWORD
      </Button>
    </div>
  );
}
