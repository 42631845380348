import React from "react";
import "./Settings.css";
import UserProfile from "./UserProfile";

export default function Settings({ handleLogout }) {
  return (
    <div className="settings-container">
      <UserProfile handleLogout={handleLogout} />
    </div>
  );
}
