import React, { useContext, useEffect, useRef, useState } from "react";
import { useSocket } from "./SocketProvider";
import { useApi } from "./ApiProvider";
import { useUser } from "./UserProvider";
import { useToast } from "@chakra-ui/react";

const DataContext = React.createContext();

export function useData() {
  return useContext(DataContext);
}

export function DataProvider({ children }) {
  const socket = useSocket();
  const { api } = useApi();
  const {
    userInboxesReadStatus,
    updateInboxThreadMode,
    updateInboxDirectConversationMode,
  } = useUser();

  const toast = useToast();

  const [messageMode, _setMessageMode] = useState("multi");

  const messageModeRef = useRef("multi");

  const setMessageMode = (mode) => {
    messageModeRef.current = mode;
    _setMessageMode(mode);
  };

  const [inbox, _setInbox] = useState(null);

  const inboxRef = useRef(null);

  const setInbox = (inbox) => {
    inboxRef.current = inbox;
    _setInbox(inbox);
  };

  const [selectedThread, _setSelectedThread] = useState(null);
  const [selectedDirectConversation, _setSelectedDirectConversation] =
    useState(null);

  const selectedThreadRef = useRef(null);
  const selectedDirectConversationRef = useRef(null);

  const setSelectedThread = (thread) => {
    selectedThreadRef.current = thread;
    _setSelectedThread(thread);
  };

  const setSelectedDirectConversation = (directConversation) => {
    selectedDirectConversationRef.current = directConversation;
    _setSelectedDirectConversation(directConversation);
  };

  const [threads, _setThreads] = useState({});
  const [messages, _setMessages] = useState({});

  const [threadsPageStates, _setThreadsPageStates] = useState({});
  const [isThreadsPageEnd, _setIsThreadsPageEnd] = useState({});

  const [threadMessagesPageStates, _setThreadMessagesPageStates] = useState({});
  const [isThreadMessagesPageEnd, _setIsThreadMessagesPageEnd] = useState({});

  const [threadsLoading, setThreadsLoading] = useState(false);
  const [threadMessagesLoading, setThreadMessagesLoading] = useState(false);

  const threadsRef = useRef({});
  const messagesRef = useRef({});
  const threadsPageStatesRef = useRef({});
  const isThreadsPageEndRef = useRef({});
  const threadMessagesPageStatesRef = useRef({});
  const isThreadMessagesPageEndRef = useRef({});

  const setThreads = (updatedThreads) => {
    threadsRef.current = Object.assign({}, updatedThreads);
    _setThreads(updatedThreads);
  };

  const setMessages = (updatedMessages) => {
    messagesRef.current = Object.assign({}, updatedMessages);
    _setMessages(updatedMessages);
  };

  const setThreadsPageStates = (updatedPageState) => {
    threadsPageStatesRef.current = Object.assign({}, updatedPageState);
    _setThreadsPageStates(updatedPageState);
  };

  const setIsThreadsPageEnd = (updatedPageEnd) => {
    isThreadsPageEndRef.current = Object.assign({}, updatedPageEnd);
    _setIsThreadsPageEnd(updatedPageEnd);
  };

  const setThreadMessagesPageStates = (updatedPageState) => {
    threadMessagesPageStatesRef.current = Object.assign({}, updatedPageState);
    _setThreadMessagesPageStates(updatedPageState);
  };

  const setIsThreadMessagesPageEnd = (updatedPageEnd) => {
    isThreadMessagesPageEndRef.current = Object.assign({}, updatedPageEnd);
    _setIsThreadMessagesPageEnd(updatedPageEnd);
  };

  const [directConversations, _setDirectConversations] = useState({});
  const [directConversationMessages, _setDirectConversationMessages] = useState(
    {}
  );

  const [directConversationsPageStates, _setDirectConversationsPageStates] =
    useState({});
  const [isDirectConversationsPageEnd, _setIsDirectConversationsPageEnd] =
    useState({});

  const [
    directConversationMessagesPageStates,
    _setDirectConversationMessagesPageStates,
  ] = useState({});
  const [
    isDirectConversationMessagesPageEnd,
    _setIsDirectConversationMessagesPageEnd,
  ] = useState({});

  const [directConversationsLoading, setDirectConversationsLoading] =
    useState(false);
  const [
    directConversationMessagesLoading,
    setDirectConversationMessagesLoading,
  ] = useState(false);

  const directConversationsRef = useRef({});
  const directConversationMessagesRef = useRef({});
  const directConversationsPageStatesRef = useRef({});
  const isDirectConversationsPageEndRef = useRef({});
  const directConversationMessagesPageStatesRef = useRef({});
  const isDirectConversationMessagesPageEndRef = useRef({});

  const setDirectConversations = (updatedDirectConversations) => {
    directConversationsRef.current = Object.assign(
      {},
      updatedDirectConversations
    );
    _setDirectConversations(updatedDirectConversations);
  };

  const setDirectConversationMessages = (
    updatedDirectConversationsMessages
  ) => {
    directConversationMessagesRef.current = Object.assign(
      {},
      updatedDirectConversationsMessages
    );
    _setDirectConversationMessages(updatedDirectConversationsMessages);
  };

  const setDirectConversationsPageStates = (updatedPageState) => {
    directConversationsPageStatesRef.current = Object.assign(
      {},
      updatedPageState
    );
    _setDirectConversationsPageStates(updatedPageState);
  };

  const setIsDirectConversationsPageEnd = (updatedPageEnd) => {
    isDirectConversationsPageEndRef.current = Object.assign({}, updatedPageEnd);
    _setIsDirectConversationsPageEnd(updatedPageEnd);
  };

  const setDirectConversationMessagesPageStates = (updatedPageState) => {
    directConversationMessagesPageStatesRef.current = Object.assign(
      {},
      updatedPageState
    );
    _setDirectConversationMessagesPageStates(updatedPageState);
  };

  const setIsDirectConversationMessagesPageEnd = (updatedPageEnd) => {
    isDirectConversationMessagesPageEndRef.current = Object.assign(
      {},
      updatedPageEnd
    );
    _setIsDirectConversationMessagesPageEnd(updatedPageEnd);
  };

  useEffect(() => {
    socket.on("message-recieved", updateRecievedThreadMessage);
    socket.on("message-sent", updateSentThreadMessage);
    socket.on("direct-message-recieved", directMessageRecieved);

    return () => {
      socket.off("message-recieved", updateRecievedThreadMessage);
      socket.off("message-sent", updateSentThreadMessage);
      socket.off("direct-message-recieved", directMessageRecieved);
    };
  }, [socket]);

  function updateSentThreadMessage({
    threadId,
    threadParticipant,
    messageText,
    sender,
    threadTopic,
    threadAuthor,
    threadParticipants,
    messageId,
    hasAttachment,
    attachmentObj,
  }) {
    const shouldAddMessage = messagesRef.current.hasOwnProperty(threadId)
      ? !messagesRef.current[threadId].some(
          (message) => message.message_id === messageId
        )
      : true;

    if (shouldAddMessage) {
      addSentMessageToLocalState(
        threadId,
        sender,
        messageText,
        messageId,
        hasAttachment,
        attachmentObj
      );
    }

    // addSentMessageToLocalState(
    //   threadId,
    //   sender,
    //   messageText,
    //   messageId,
    //   hasAttachment,
    //   attachmentObj
    // );

    updateThreadInLocalState(
      threadId,
      threadParticipant,
      messageText,
      sender,
      threadTopic,
      threadAuthor,
      threadParticipants,
      hasAttachment,
      attachmentObj
    );
    updateLastReadThreadMessage(sender, threadId, messageId);
  }

  function updateRecievedThreadMessage({
    threadId,
    threadParticipant,
    messageText,
    sender,
    threadTopic,
    threadAuthor,
    threadParticipants,
    messageId,
    hasAttachment,
    attachmentObj,
  }) {
    const shouldAddMessage = messagesRef.current.hasOwnProperty(threadId)
      ? !messagesRef.current[threadId].some(
          (message) => message.message_id === messageId
        )
      : true;

    if (shouldAddMessage) {
      addRecievedMessageToLocalState(
        threadId,
        messageText,
        sender,
        messageId,
        hasAttachment,
        attachmentObj
      );
    }

    // addRecievedMessageToLocalState(
    //   threadId,
    //   messageText,
    //   sender,
    //   messageId,
    //   hasAttachment,
    //   attachmentObj
    // );

    updateThreadInLocalState(
      threadId,
      threadParticipant,
      messageText,
      sender,
      threadTopic,
      threadAuthor,
      threadParticipants,
      hasAttachment,
      attachmentObj
    );
    if (
      messageModeRef.current === "multi" &&
      inboxRef.current === threadParticipant &&
      selectedThreadRef.current?.thread_id === threadId
    ) {
      updateLastReadThreadMessage(threadParticipant, threadId, messageId);
      updateInboxThreadMode(threadParticipant, true);
    } else if (
      messageModeRef.current === "multi" &&
      inboxRef.current === threadParticipant &&
      selectedThreadRef.current?.thread_id !== threadId
    ) {
      updateInboxThreadMode(threadParticipant, true);
    } else if (userInboxesReadStatus[threadParticipant].thread_read) {
      updateInboxThreadMode(threadParticipant, false);
    }
    // if (selectedThreadRef.current?.thread_id !== threadId) {
    //   toast({
    //     position: "top-right",
    //     duration: "15000",
    //     isClosable: true,
    //     render: () => (
    //       <div
    //         style={{
    //           color: "white",
    //           padding: "15px",
    //           backgroundColor: "#0059B3",
    //           borderRadius: "9px",
    //         }}
    //       >
    //         {messageText}
    //       </div>
    //     ),
    //   });
    // }
  }

  function updateThreadInLocalState(
    threadId,
    threadParticipant,
    messageText,
    sender,
    threadTopic,
    threadAuthor,
    threadParticipants,
    hasAttachment,
    attachmentObj
  ) {
    const threadObj = {};

    if (threadsRef.current.hasOwnProperty(threadParticipant)) {
      const threadsArrToUpdate = [...threadsRef.current[threadParticipant]];
      const threadObjToUpdateIndex = threadsArrToUpdate.findIndex(
        (thread) => thread.thread_id === threadId
      );
      const timestamp = new Date().toISOString();

      if (threadObjToUpdateIndex > -1) {
        threadsArrToUpdate[threadObjToUpdateIndex].message = messageText;
        threadsArrToUpdate[threadObjToUpdateIndex].sender = sender;
        threadsArrToUpdate[threadObjToUpdateIndex].thread_read =
          threadParticipant === sender;
        threadsArrToUpdate[threadObjToUpdateIndex].modified_at = timestamp;
        threadsArrToUpdate[threadObjToUpdateIndex].has_attachment =
          hasAttachment;
        if (hasAttachment) {
          threadsArrToUpdate[threadObjToUpdateIndex].attachment_name =
            attachmentObj.attachment_name;
          threadsArrToUpdate[threadObjToUpdateIndex].attachment_object_name =
            attachmentObj.attachment_object_name;
          threadsArrToUpdate[threadObjToUpdateIndex].attachment_key =
            attachmentObj.attachment_key;
          threadsArrToUpdate[threadObjToUpdateIndex].attachment_size =
            attachmentObj.attachment_size;
          threadsArrToUpdate[threadObjToUpdateIndex].attachment_mimetype =
            attachmentObj.attachment_mimetype;
          threadsArrToUpdate[threadObjToUpdateIndex].attachment_uploaded_on =
            timestamp;
        }

        const sortedUpdatedThreadsArr = threadsArrToUpdate.sort(function (
          x,
          y
        ) {
          var a = new Date(x.modified_at);
          var b = new Date(y.modified_at);
          if (a < b) return 1;
          if (a > b) return -1;
        });

        threadObj[threadParticipant] = sortedUpdatedThreadsArr;
      } else {
        const newThreadObj = {};
        newThreadObj.message = messageText;
        newThreadObj.sender = sender;
        newThreadObj.thread_read = threadParticipant === sender;
        newThreadObj.modified_at = new Date().toISOString();
        newThreadObj.thread_topic = threadTopic;
        newThreadObj.has_attachment = false;
        newThreadObj.thread_id = threadId;
        newThreadObj.thread_participants = threadParticipants;
        newThreadObj.user_inbox = threadParticipant;
        newThreadObj.thread_author = threadAuthor;
        newThreadObj.has_attachment = hasAttachment;
        if (hasAttachment) {
          newThreadObj.attachment_name = attachmentObj.attachment_name;
          newThreadObj.attachment_object_name =
            attachmentObj.attachment_object_name;
          newThreadObj.attachment_key = attachmentObj.attachment_key;
          newThreadObj.attachment_size = attachmentObj.attachment_size;
          newThreadObj.attachment_mimetype = attachmentObj.attachment_mimetype;
          newThreadObj.attachment_uploaded_on =
            attachmentObj.attachment_uploaded_on;
        }
        threadsArrToUpdate.unshift(newThreadObj);
        threadObj[threadParticipant] = threadsArrToUpdate;
      }
    }
    const updatedThreads = Object.assign({}, threadsRef.current, threadObj);
    setThreads(updatedThreads);
  }

  function markThreadAsReadInLocalState(inbox, threadId) {
    const threadsArrToUpdate = [...threadsRef.current[inbox]];
    const threadObjToUpdateIndex = threadsArrToUpdate.findIndex(
      (thread) => thread.thread_id === threadId
    );
    threadsArrToUpdate[threadObjToUpdateIndex].thread_read = true;
  }

  function updateLastReadThreadMessage(inbox, threadId, lastReadMessageId) {
    if (threadsRef.current.hasOwnProperty(inbox)) {
      const threadObj = {};

      const threadsArrToUpdate = [...threadsRef.current[inbox]];
      const threadObjToUpdateIndex = threadsArrToUpdate.findIndex(
        (thread) => thread.thread_id === threadId
      );
      threadsArrToUpdate[threadObjToUpdateIndex].last_read_message_id =
        lastReadMessageId;

      threadObj[inbox] = threadsArrToUpdate;

      const updatedThreads = Object.assign({}, threadsRef.current, threadObj);
      setThreads(updatedThreads);

      api("/api/threads/update-last-read-message", "POST", {
        last_read_message_id: lastReadMessageId,
        user_inbox: inbox,
        thread_id: threadId,
      })
        .then((data) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function addSentMessageToLocalState(
    threadId,
    sender,
    messageText,
    messageId,
    hasAttachment,
    attachmentObj
  ) {
    const messageObj = {
      thread_id: threadId,
      timestamp: new Date().toISOString(),
      message: messageText,
      sender: sender,
      message_id: messageId,
      has_attachment: hasAttachment,
    };

    Object.assign(messageObj, attachmentObj); //Append 'attachmentObj' to 'messageObj'

    const newMessageObj = {};
    if (messagesRef.current.hasOwnProperty(threadId)) {
      const currentMessages = [...messagesRef.current[threadId]];
      currentMessages.unshift(messageObj);
      newMessageObj[threadId] = currentMessages;
    } else {
      newMessageObj[threadId] = [messageObj];
    }
    const updatedMessageObj = Object.assign(
      {},
      messagesRef.current,
      newMessageObj
    );
    setMessages(updatedMessageObj);
  }

  function addRecievedMessageToLocalState(
    threadId,
    messageText,
    sender,
    messageId,
    hasAttachment,
    attachmentObj
  ) {
    const messageObj = {
      thread_id: threadId,
      timestamp: new Date().toISOString(),
      message: messageText,
      sender: sender,
      message_id: messageId,
      has_attachment: hasAttachment,
    };

    Object.assign(messageObj, attachmentObj); //Append 'attachmentObj' to 'messageObj'

    const newMessageObj = {};
    if (messagesRef.current.hasOwnProperty(threadId)) {
      const currentMessages = [...messagesRef.current[threadId]];
      currentMessages.unshift(messageObj);
      newMessageObj[threadId] = currentMessages;
    }

    const updatedMessageObj = Object.assign(
      {},
      messagesRef.current,
      newMessageObj
    );
    setMessages(updatedMessageObj);
  }

  function directMessageRecieved({
    directConversationId,
    conversationRecipient,
    userInbox,
    sender,
    recipient,
    message,
    messageId,
    hasAttachment,
    attachmentObj,
  }) {
    addDirectMessage({
      directConversationId,
      conversationRecipient,
      userInbox,
      sender,
      recipient,
      message,
      messageId,
      hasAttachment,
      attachmentObj,
    });

    if (
      messageModeRef.current === "single" &&
      inboxRef.current === userInbox &&
      selectedDirectConversationRef.current?.direct_conversation_id ===
        directConversationId
    ) {
      updateLastReadDirectConversationMessage(
        userInbox,
        directConversationId,
        messageId
      );
      updateInboxDirectConversationMode(userInbox, true);
    } else if (
      messageModeRef.current === "single" &&
      inboxRef.current === userInbox &&
      selectedDirectConversationRef.current?.direct_conversation_id !==
        directConversationId
    ) {
      updateInboxDirectConversationMode(userInbox, true);
    } else if (userInboxesReadStatus[userInbox].direct_conversation_read) {
      updateInboxDirectConversationMode(userInbox, false);
    }
  }

  function addDirectMessage({
    directConversationId,
    conversationRecipient,
    userInbox,
    sender,
    recipient,
    message,
    messageId,
    hasAttachment,
    attachmentObj,
  }) {
    addDirectConversationMessageToLocalState(
      directConversationId,
      sender,
      recipient,
      message,
      messageId,
      hasAttachment,
      attachmentObj
    );
    updateDirectConversationInLocalState(
      directConversationId,
      conversationRecipient,
      userInbox,
      sender,
      recipient,
      message,
      hasAttachment,
      attachmentObj
    );
  }

  function updateDirectConversationInLocalState(
    directConversationId,
    conversationRecipient,
    userInbox,
    sender,
    recipient,
    message,
    hasAttachment,
    attachmentObj
  ) {
    const directConvObj = {};

    if (directConversationsRef.current.hasOwnProperty(userInbox)) {
      const directConvsArrToUpdate = [
        ...directConversationsRef.current[userInbox],
      ];
      const directConvObjToUpdateIndex = directConvsArrToUpdate.findIndex(
        (directConv) =>
          directConv.direct_conversation_id === directConversationId
      );
      const timestamp = new Date().toISOString();

      if (directConvObjToUpdateIndex > -1) {
        directConvsArrToUpdate[directConvObjToUpdateIndex].message = message;
        directConvsArrToUpdate[directConvObjToUpdateIndex].message_sender =
          sender;
        directConvsArrToUpdate[directConvObjToUpdateIndex].message_recipient =
          recipient;
        directConvsArrToUpdate[directConvObjToUpdateIndex].conversation_read =
          userInbox === sender;
        directConvsArrToUpdate[directConvObjToUpdateIndex].modified_at =
          timestamp;
        directConvsArrToUpdate[directConvObjToUpdateIndex].has_attachment =
          hasAttachment;
        if (hasAttachment) {
          directConvsArrToUpdate[directConvObjToUpdateIndex].attachment_name =
            attachmentObj.attachment_name;
          directConvsArrToUpdate[
            directConvObjToUpdateIndex
          ].attachment_object_name = attachmentObj.attachment_object_name;
          directConvsArrToUpdate[directConvObjToUpdateIndex].attachment_key =
            attachmentObj.attachment_key;
          directConvsArrToUpdate[directConvObjToUpdateIndex].attachment_size =
            attachmentObj.attachment_size;
          directConvsArrToUpdate[
            directConvObjToUpdateIndex
          ].attachment_mimetype = attachmentObj.attachment_mimetype;
          directConvsArrToUpdate[
            directConvObjToUpdateIndex
          ].attachment_uploaded_on = timestamp;
        }

        const sortedUpdatedDirectConvsArr = directConvsArrToUpdate.sort(
          function (x, y) {
            var a = new Date(x.modified_at);
            var b = new Date(y.modified_at);
            if (a < b) return 1;
            if (a > b) return -1;
          }
        );

        directConvObj[userInbox] = sortedUpdatedDirectConvsArr;
      } else {
        const newDirectConvObj = {};
        newDirectConvObj.message = message;
        newDirectConvObj.message_sender = sender;
        newDirectConvObj.message_recipient = recipient;
        newDirectConvObj.conversation_read = userInbox === sender;
        newDirectConvObj.modified_at = timestamp;
        newDirectConvObj.has_attachment = hasAttachment;
        newDirectConvObj.direct_conversation_id = directConversationId;
        newDirectConvObj.user_inbox = userInbox;
        newDirectConvObj.conversation_recipient = conversationRecipient;
        if (hasAttachment) {
          newDirectConvObj.attachment_name = attachmentObj.attachment_name;
          newDirectConvObj.attachment_object_name =
            attachmentObj.attachment_object_name;
          newDirectConvObj.attachment_key = attachmentObj.attachment_key;
          newDirectConvObj.attachment_size = attachmentObj.attachment_size;
          newDirectConvObj.attachment_mimetype =
            attachmentObj.attachment_mimetype;
          newDirectConvObj.attachment_uploaded_on = timestamp;
        }
        directConvsArrToUpdate.unshift(newDirectConvObj);
        directConvObj[userInbox] = directConvsArrToUpdate;
      }
    }

    const updatedDirectConvs = Object.assign(
      {},
      directConversationsRef.current,
      directConvObj
    );
    setDirectConversations(updatedDirectConvs);
  }

  function addDirectConversationMessageToLocalState(
    directConversationId,
    sender,
    recipient,
    messageText,
    messageId,
    hasAttachment,
    attachmentObj
  ) {
    const messageObj = {
      direct_conversation_id: directConversationId,
      timestamp: new Date().toISOString(),
      message: messageText,
      sender: sender,
      recipient: recipient,
      message_id: messageId,
      has_attachment: hasAttachment,
    };

    Object.assign(messageObj, attachmentObj); //Append 'attachmentObj' to 'messageObj'

    const newMessageObj = {};
    if (
      directConversationMessagesRef.current.hasOwnProperty(directConversationId)
    ) {
      const currentDirectConversationMessages = [
        ...directConversationMessagesRef.current[directConversationId],
      ];
      currentDirectConversationMessages.unshift(messageObj);
      newMessageObj[directConversationId] = currentDirectConversationMessages;
    }

    const updatedMessageObj = Object.assign(
      {},
      directConversationMessagesRef.current,
      newMessageObj
    );
    setDirectConversationMessages(updatedMessageObj);
  }

  function updateLastReadDirectConversationMessage(
    inbox,
    directConversationId,
    lastReadMessageId
  ) {
    if (directConversationsRef.current.hasOwnProperty(inbox)) {
      const directConvObj = {};

      const directConvsArrToUpdate = [...directConversationsRef.current[inbox]];
      const directConvObjToUpdateIndex = directConvsArrToUpdate.findIndex(
        (directConv) =>
          directConv.direct_conversation_id === directConversationId
      );
      directConvsArrToUpdate[directConvObjToUpdateIndex].last_read_message_id =
        lastReadMessageId;

      directConvObj[inbox] = directConvsArrToUpdate;

      const updatedDirectConversations = Object.assign(
        {},
        directConversationsRef.current,
        directConvObj
      );
      setDirectConversations(updatedDirectConversations);

      api("/api/direct-conversations/update-last-read-message", "POST", {
        last_read_message_id: lastReadMessageId,
        user_inbox: inbox,
        direct_conversation_id: directConversationId,
      })
        .then((data) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function getThreads(selectedInbox) {
    setThreadsLoading(true);
    api("/api/threads/get-by-user-inbox", "POST", {
      user_inbox: selectedInbox,
      // pageState: threadsPageStates.hasOwnProperty(selectedInbox)
      //   ? threadsPageStates[selectedInbox]
      //     ? threadsPageStates[selectedInbox]
      //     : null
      //   : null,
    })
      .then((data) => {
        const resultThreads = data.threads;
        if (resultThreads.length > 0) {
          const sortedResultThreads = resultThreads.sort(function (x, y) {
            var a = new Date(x.modified_at);
            var b = new Date(y.modified_at);
            if (a < b) return 1;
            if (a > b) return -1;
          });

          const threadObj = {};
          threadObj[selectedInbox] = sortedResultThreads;

          const updatedThreads = Object.assign({}, threads, threadObj);

          setThreads(updatedThreads);

          // const nextPageState = data.nextPageState;
          // let pageEnd = false;
          // if (nextPageState === null) {
          //   pageEnd = true;
          // }

          // const pageStateObj = {};
          // pageStateObj[selectedInbox] = nextPageState;

          // const updatedPageStateObj = Object.assign(
          //   {},
          //   threadsPageStates,
          //   pageStateObj
          // );
          // setThreadsPageStates(updatedPageStateObj);

          // const pageEndObj = {};
          // pageEndObj[selectedInbox] = pageEnd;

          // const updatedPageEndObj = Object.assign(
          //   {},
          //   isThreadsPageEnd,
          //   pageEndObj
          // );
          // setIsThreadsPageEnd(updatedPageEndObj);
        } else {
          const threadObj = {};
          threadObj[selectedInbox] = [];

          const updatedThreads = Object.assign({}, threads, threadObj);
          setThreads(updatedThreads);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setThreadsLoading(false);
      });
  }

  function getThreadMessages(selectedThread, selectedInbox) {
    setThreadMessagesLoading(true);
    api("/api/thread-messages/get-thread-messages", "POST", {
      thread_id: selectedThread.thread_id,
      user_inbox: selectedInbox,
      pageState: threadMessagesPageStates.hasOwnProperty(
        selectedThread.thread_id
      )
        ? threadMessagesPageStates[selectedThread.thread_id]
          ? threadMessagesPageStates[selectedThread.thread_id]
          : null
        : null,
    })
      .then((data) => {
        const messageObj = {};
        const prevMessages = messages.hasOwnProperty(selectedThread.thread_id)
          ? [...messages[selectedThread.thread_id]]
          : [];
        messageObj[selectedThread.thread_id] = [
          ...prevMessages,
          ...data.messages,
        ];

        const updatedMessages = Object.assign({}, messages, messageObj);
        setMessages(updatedMessages);

        const nextPageState = data.nextPageState;
        let pageEnd = false;
        if (nextPageState === null) {
          pageEnd = true;
        }

        const pageStateObj = {};
        pageStateObj[selectedThread.thread_id] = nextPageState;

        const updatedPageStateObj = Object.assign(
          {},
          threadMessagesPageStates,
          pageStateObj
        );
        setThreadMessagesPageStates(updatedPageStateObj);

        const pageEndObj = {};
        pageEndObj[selectedThread.thread_id] = pageEnd;

        const updatedPageEndObj = Object.assign(
          {},
          isThreadMessagesPageEnd,
          pageEndObj
        );
        setIsThreadMessagesPageEnd(updatedPageEndObj);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setThreadMessagesLoading(false);
      });
  }

  function markDirectConversationAsReadInLocalState(
    inbox,
    directConversationId
  ) {
    const directConvsArrToUpdate = [...directConversationsRef.current[inbox]];
    const directConvObjToUpdateIndex = directConvsArrToUpdate.findIndex(
      (directConv) => directConv.direct_conversation_id === directConversationId
    );
    directConvsArrToUpdate[directConvObjToUpdateIndex].conversation_read = true;
  }

  function getDirectConversations(inbox) {
    setDirectConversationsLoading(true);
    api("/api/direct-conversations/get-by-user-inbox", "POST", {
      user_inbox: inbox,
      // pageState: directConversationsPageStates.hasOwnProperty(inbox)
      //   ? directConversationsPageStates[inbox]
      //     ? directConversationsPageStates[inbox]
      //     : null
      //   : null,
    })
      .then((data) => {
        const directConvs = data.directConversations;
        if (directConvs.length > 0) {
          const sortedDirectConvs = directConvs.sort(function (x, y) {
            var a = new Date(x.modified_at);
            var b = new Date(y.modified_at);
            if (a < b) return 1;
            if (a > b) return -1;
          });

          const directConvObj = {};
          directConvObj[inbox] = sortedDirectConvs;

          const updatedDirectConvs = Object.assign(
            {},
            directConversations,
            directConvObj
          );

          setDirectConversations(updatedDirectConvs);

          // const nextPageState = data.nextPageState;
          // let pageEnd = false;
          // if (nextPageState === null) {
          //   pageEnd = true;
          // }

          // const pageStateObj = {};
          // pageStateObj[inbox] = nextPageState;

          // const updatedPageStateObj = Object.assign(
          //   {},
          //   directConversationsPageStates,
          //   pageStateObj
          // );
          // setDirectConversationsPageStates(updatedPageStateObj);

          // const pageEndObj = {};
          // pageEndObj[inbox] = pageEnd;

          // const updatedPageEndObj = Object.assign(
          //   {},
          //   isDirectConversationsPageEnd,
          //   pageEndObj
          // );
          // setIsDirectConversationsPageEnd(updatedPageEndObj);
        } else {
          const directConvObj = {};
          directConvObj[inbox] = [];

          const updatedDirectConvs = Object.assign(
            {},
            directConversations,
            directConvObj
          );

          setDirectConversations(updatedDirectConvs);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setDirectConversationsLoading(false);
      });
  }

  function getDirectConversationMessages(inbox, directConversationId) {
    setDirectConversationMessagesLoading(true);
    api("/api/direct-messages/get-direct-conversation-messages", "POST", {
      user_inbox: inbox,
      direct_conversation_id: directConversationId,
      pageState: directConversationMessagesPageStates.hasOwnProperty(
        directConversationId
      )
        ? directConversationMessagesPageStates[directConversationId]
          ? directConversationMessagesPageStates[directConversationId]
          : null
        : null,
    })
      .then((data) => {
        const directConvMessagesObj = {};
        const prevDirectConvMessages =
          directConversationMessages.hasOwnProperty(directConversationId)
            ? [...directConversationMessages[directConversationId]]
            : [];
        directConvMessagesObj[directConversationId] = [
          ...prevDirectConvMessages,
          ...data.messages,
        ];

        const updatedDirectConvMessages = Object.assign(
          {},
          directConversationMessages,
          directConvMessagesObj
        );
        setDirectConversationMessages(updatedDirectConvMessages);

        const nextPageState = data.nextPageState;
        let pageEnd = false;
        if (nextPageState === null) {
          pageEnd = true;
        }

        const pageStateObj = {};
        pageStateObj[directConversationId] = nextPageState;

        const updatedPageStateObj = Object.assign(
          {},
          directConversationMessagesPageStates,
          pageStateObj
        );
        setDirectConversationMessagesPageStates(updatedPageStateObj);

        const pageEndObj = {};
        pageEndObj[directConversationId] = pageEnd;

        const updatedPageEndObj = Object.assign(
          {},
          isDirectConversationMessagesPageEnd,
          pageEndObj
        );
        setIsDirectConversationMessagesPageEnd(updatedPageEndObj);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setDirectConversationMessagesLoading(false);
      });
  }

  function sendDirectConversationMessage({
    directConversationId,
    conversationRecipient,
    sender,
    recipient,
    userInbox,
    message,
    messageId,
    hasAttachment,
    attachmentObj,
  }) {
    addDirectMessage({
      directConversationId,
      conversationRecipient,
      userInbox,
      sender,
      recipient,
      message,
      messageId,
      hasAttachment,
      attachmentObj,
    });
  }

  return (
    <DataContext.Provider
      value={{
        inbox,
        setInbox,
        messageMode,
        setMessageMode,
        selectedThread,
        setSelectedThread,
        selectedDirectConversation,
        setSelectedDirectConversation,
        threads,
        getThreads,
        messages,
        getThreadMessages,
        markThreadAsReadInLocalState,
        updateLastReadThreadMessage,
        threadMessagesPageStates,
        isThreadMessagesPageEnd,
        threadsLoading,
        threadMessagesLoading,
        directConversations,
        getDirectConversations,
        directConversationMessages,
        getDirectConversationMessages,
        markDirectConversationAsReadInLocalState,
        updateLastReadDirectConversationMessage,
        directConversationMessagesPageStates,
        isDirectConversationMessagesPageEnd,
        sendDirectConversationMessage,
        directConversationsLoading,
        directConversationMessagesLoading,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}
