import React from "react";
import "./Threads.css";
import { useData } from "../contexts/DataProvider";
import Loader from "./loader/Loader";
import { dateParserShort } from "../utils/date-time-parser";
// import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
// import { ThreeDots } from "react-bootstrap-icons";
import { SlideFade } from "@chakra-ui/react";

export default function Threads({
  selectedInbox,
  handleThread,
  selectedThread,
}) {
  const { threads, threadsLoading } = useData();

  if (threadsLoading) {
    return <Loader />;
  }

  return (
    <>
      {threads &&
        threads[selectedInbox] &&
        threads[selectedInbox].map((thread, index) => {
          return (
            <SlideFade in={true}>
              <div
                className={
                  selectedThread
                    ? thread.thread_id === selectedThread.thread_id
                      ? "selected-thread"
                      : thread.thread_read
                      ? "thread"
                      : "unread-thread"
                    : thread.thread_read
                    ? "thread"
                    : "unread-thread"
                }
                onClick={() => handleThread(thread)}
                key={thread.thread_id}
              >
                <div className="thread-topic-container">
                  <div className="thread-topic">{`${thread.thread_topic}`}</div>
                  <div className="thread-modified-time">
                    {dateParserShort(thread.modified_at)}
                  </div>
                </div>
                <div className="latest-thread-message">
                  <div className="sender-container">
                    <div className="sender-inbox-label">{`${thread.sender}:`}</div>
                    {!thread.thread_read && <span className="dot" />}
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="thread-message-content">
                      {thread.message}
                    </div>
                    {/* <Menu autoSelect={false} isLazy={true} placement="bottom-end">
                    <MenuButton onClick={(e) => e.stopPropagation()}>
                      <ThreeDots />
                    </MenuButton>
                    <MenuList>
                      <MenuItem>Delete Conversation</MenuItem>
                    </MenuList>
                  </Menu> */}
                  </div>
                </div>
              </div>
            </SlideFade>
          );
        })}
    </>
  );
}
