import React from "react";
import "./ThreadTopic.css";
// import {
//   Button,
//   Collapse,
//   Menu,
//   MenuButton,
//   MenuItem,
//   MenuList,
//   Text,
// } from "@chakra-ui/react";
// import { ThreeDotsVertical } from "react-bootstrap-icons";

export default function ThreadTopic({ threadTopic }) {
  return (
    <div
      style={{
        height: "80px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          overflow: "auto",
        }}
      >
        <div className="thread-topic-text">{threadTopic}</div>
        {/* <Menu autoSelect={false} isLazy={true} placement="bottom-end">
          <MenuButton>
            <ThreeDotsVertical />
          </MenuButton>
          <MenuList>
            <MenuItem>Clear All Messages</MenuItem>
          </MenuList>
        </Menu> */}
      </div>
    </div>
  );
}
