import React from "react";
import "./MessageModeSwitch.css";
import { PeopleFill, PersonFill } from "react-bootstrap-icons";
import { useUser } from "../contexts/UserProvider";
import { Fade } from "@chakra-ui/react";

export default function MessageModeSwitch({
  messageMode,
  handleMessageMode,
  selectedInbox,
}) {
  const { userInboxesReadStatus } = useUser();

  return (
    <div
      style={{
        flex: "1 0 auto",
        padding: "0px 25px",
        textAlign: "center",
      }}
    >
      <div className="message-mode-switch-button">
        <div
          className={
            messageMode === "multi"
              ? "message-mode-section active-message-mode-section"
              : "message-mode-section"
          }
          onClick={() => handleMessageMode("multi")}
        >
          {userInboxesReadStatus[selectedInbox].thread_read === null ||
          !userInboxesReadStatus[selectedInbox].thread_read ? (
            <Fade in={true}>
              <div className="message-mode-unread-indicator" />
            </Fade>
          ) : (
            <div className="empty-placeholder" />
          )}
          <PeopleFill color="#0059B3" />
          <div className="empty-placeholder" />
        </div>
        <div
          className={
            messageMode === "single"
              ? "message-mode-section active-message-mode-section"
              : "message-mode-section"
          }
          onClick={() => handleMessageMode("single")}
        >
          <div className="empty-placeholder" />
          <PersonFill color="#FF6200" />
          {userInboxesReadStatus[selectedInbox].direct_conversation_read ===
            null ||
          !userInboxesReadStatus[selectedInbox].direct_conversation_read ? (
            <Fade in={true}>
              <div className="message-mode-unread-indicator" />
            </Fade>
          ) : (
            <div className="empty-placeholder" />
          )}
        </div>
      </div>
    </div>
  );
}
