import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  InputGroup,
  InputLeftElement,
  Link as ChakraLink,
  ScaleFade,
  useToast,
} from "@chakra-ui/react";
import { useAuth } from "../contexts/AuthProvider";
import { useUser } from "../contexts/UserProvider";
import "./Login.css";
import { LockFill, PersonFill } from "react-bootstrap-icons";
import { ReactComponent as RelayBeam } from "../graphics/RelayBeam.svg";
import { Link as ReactRouterLink } from "react-router-dom";

export default function Login({ setView }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setToken } = useAuth();
  const { getUser, getUserInboxes } = useUser();
  const toast = useToast();

  function handleSubmit(e) {
    e.preventDefault();

    if (username === "") {
      toast({
        title: "The username can not be empty",
        description: "Please, enter your username",
        status: "error",
        position: "top",
        duration: 5000,
        isClosable: true,
      });
    } else if (password === "") {
      toast({
        title: "The password can not be empty",
        description: "Please, enter your password",
        status: "error",
        position: "top",
        duration: 5000,
        isClosable: true,
      });
    } else {
      fetch(process.env.REACT_APP_API_URL + "/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
        mode: "cors",
        credentials: "include",
      })
        .then((response) => {
          if (response.ok) return response.json();
          toast({
            title: "The username or password is incorrect",
            description: "Please, try again",
            status: "error",
            position: "top",
            duration: 5000,
            isClosable: true,
          });
          throw new Error("Invalid credentials");
        })
        .then((data) => {
          setToken(data.accessToken);
          getUser(username);
          getUserInboxes(username);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <div>
      <ScaleFade initialScale={0.9} in={true}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Box boxShadow="lg" className="login-box">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <RelayBeam className="relaybeam-logo" />
            </div>

            <div style={{ textAlign: "center", color: "gray" }}>
              Welcome to the beta version! Your insights and engagement are key
              as we evolve. Let's innovate together!
            </div>

            <Box my={4} textAlign="left">
              <form onSubmit={handleSubmit}>
                <FormControl>
                  <FormLabel
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    USERNAME
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<PersonFill color="#0059B3" />}
                    />
                    <Input
                      size="md"
                      onChange={(e) => setUsername(e.currentTarget.value)}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl mt={6}>
                  <FormLabel
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    PASSWORD
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<LockFill color="#0059B3" />}
                    />
                    <Input
                      type="password"
                      size="md"
                      onChange={(e) => setPassword(e.currentTarget.value)}
                    />
                  </InputGroup>
                </FormControl>
                {/* <div className="forgot-password">
                  <ChakraLink
                    style={{ cursor: "pointer" }}
                    onClick={() => setView("forgot-password")}
                  >
                    Forgot password?
                  </ChakraLink>
                </div> */}
                <Button
                  style={{
                    backgroundColor: "#0059B3",
                    color: "white",
                    marginTop: "30px",
                  }}
                  width="full"
                  type="submit"
                >
                  Log In
                </Button>
                <div className="create-account">
                  Don't have an account?{" "}
                  <ChakraLink
                    as={ReactRouterLink}
                    to="/register"
                    color="#FF6200"
                    style={{ fontWeight: "bold" }}
                  >
                    Register
                  </ChakraLink>
                </div>
                <div className="learn-more">
                  <ChakraLink
                    as={ReactRouterLink}
                    to="/about"
                    color="#0059B3"
                    style={{ fontWeight: "bold" }}
                  >
                    Learn more about RelayBeam
                  </ChakraLink>
                </div>
              </form>
            </Box>
          </Box>
        </div>
      </ScaleFade>
    </div>
  );
}
