import React, { useState } from "react";
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
} from "@chakra-ui/react";
import { Dash } from "react-bootstrap-icons";
import { useUser } from "../contexts/UserProvider";
import { inboxIdValidator } from "../utils/utils";
import { useApi } from "../contexts/ApiProvider";

export default function AddInbox({ handleLogout, handleNextPage }) {
  const { user } = useUser();
  const { api } = useApi();

  const [inboxes, setInboxes] = useState([""]);
  const [inboxAddresses, setInboxAddresses] = useState([""]);
  const [inboxesValidity, setInboxesValidity] = useState([false]);

  function addInbox() {
    setInboxes((prevState) => [...prevState, ""]);
    setInboxAddresses((prevState) => [...prevState, ""]);
    setInboxesValidity((prevState) => [...prevState, false]);
  }

  function handleInboxChange(index, e) {
    let newInboxes = [...inboxes];
    let newInboxAddresses = [...inboxAddresses];
    let newInboxesValidity = [...inboxesValidity];

    newInboxes[index] = e.target.value;
    newInboxAddresses[index] = user.username.concat("@", e.target.value);
    newInboxesValidity[index] =
      inboxIdValidator(e.target.value) && !inboxes.includes(e.target.value);

    setInboxes(newInboxes);
    setInboxAddresses(newInboxAddresses);
    setInboxesValidity(newInboxesValidity);
  }

  function removeInbox(index) {
    let newInboxes = [...inboxes];
    let newInboxAddresses = [...inboxAddresses];
    let newInboxesValidity = [...inboxesValidity];

    newInboxes.splice(index, 1);
    newInboxAddresses.splice(index, 1);
    newInboxesValidity.splice(index, 1);

    setInboxes(newInboxes);
    setInboxAddresses(newInboxAddresses);
    setInboxesValidity(newInboxesValidity);
  }

  function createInboxes() {
    api("/api/inboxes/create", "POST", {
      username: user.username,
      inboxes: inboxAddresses,
    })
      .then((data) => {
        handleNextPage("user-profile-pic");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "500px",
      }}
    >
      <div style={{ fontSize: "30px", color: "gray" }}>Add Port</div>
      <Divider />
      <ul
        style={{
          marginTop: "20px",
          fontWeight: "bold",
          color: "#FF6200",
          fontStyle: "italic",
        }}
      >
        <li>You can add upto 5 ports.</li>
        <li>Port label should be of 2-15 characters.</li>
        <li>
          Port label should only have lowercase letters, numbers, hyphens - or
          underscores _ with first and last character only letter or number.
        </li>
        <li style={{ color: "#0059b3" }}>
          Example: If you a create port with the label as 'work', then the
          corresponding RelayBeam ID becomes '{`${user.username}`}@work'.
        </li>
      </ul>
      {inboxes.map((inbox, index) => (
        <FormControl mt={6} isInvalid={inbox !== "" && !inboxesValidity[index]}>
          <FormLabel>{`Port #${index + 1} (Enter only port label)`}</FormLabel>
          <div style={{ display: "flex" }}>
            <Input
              size="lg"
              value={inbox}
              onChange={(e) => handleInboxChange(index, e)}
              style={{ marginRight: "10px" }}
            />
            <IconButton
              icon={<Dash />}
              style={{ height: "auto" }}
              isDisabled={index === 0 && inboxes.length === 1}
              onClick={() => removeInbox(index)}
            />
          </div>
          {inbox !== "" && inboxesValidity[index] ? (
            <FormHelperText>{inboxAddresses[index]}</FormHelperText>
          ) : (
            <FormErrorMessage>Port label is invalid</FormErrorMessage>
          )}
        </FormControl>
      ))}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <Button
          mt={4}
          style={{ backgroundColor: "#FF6200", color: "white" }}
          onClick={() => addInbox()}
          isDisabled={inboxes.length >= 5 || inboxes[inboxes.length - 1] === ""}
        >
          Add another port
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Button mt={4} onClick={() => handleLogout()}>
          Log Out
        </Button>
        <Button
          style={{ backgroundColor: "#0059B3", color: "white" }}
          mt={4}
          isDisabled={
            inboxes[inboxes.length - 1] === "" ||
            inboxes.includes("") ||
            inboxesValidity.includes(false)
          }
          onClick={() => createInboxes()}
        >
          Continue
        </Button>
      </div>
    </div>
  );
}
