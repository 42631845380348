import React, { useState } from "react";
import "./InboxConfig.css";
import { useUser } from "../../contexts/UserProvider";
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  useToast,
} from "@chakra-ui/react";
import { Dash, Envelope } from "react-bootstrap-icons";
import { inboxIdValidator } from "../../utils/utils";
import { useApi } from "../../contexts/ApiProvider";

export default function InboxConfig({ handleLogout }) {
  const { user, userInboxes } = useUser();
  const { api } = useApi();
  const toast = useToast();

  const [editInboxes, setEditInboxes] = useState(false);

  const [inboxes, setInboxes] = useState([""]);
  const [inboxAddresses, setInboxAddresses] = useState([""]);

  const [inboxesValidity, setInboxesValidity] = useState([false]);

  function addInbox() {
    setInboxes((prevState) => [...prevState, ""]);
    setInboxAddresses((prevState) => [...prevState, ""]);
    setInboxesValidity((prevState) => [...prevState, false]);
  }

  function handleInboxChange(index, e) {
    let newInboxes = [...inboxes];
    let newInboxAddresses = [...inboxAddresses];
    let newInboxesValidity = [...inboxesValidity];

    newInboxes[index] = e.target.value;
    newInboxAddresses[index] = user.username.concat("@", e.target.value);
    newInboxesValidity[index] =
      inboxIdValidator(e.target.value) &&
      !userInboxes.includes(user.username.concat("@", e.target.value)) &&
      !inboxes.includes(e.target.value);

    setInboxes(newInboxes);
    setInboxAddresses(newInboxAddresses);
    setInboxesValidity(newInboxesValidity);
  }

  function removeInbox(index) {
    let newInboxes = [...inboxes];
    let newInboxAddresses = [...inboxAddresses];
    let newInboxesValidity = [...inboxesValidity];

    newInboxes.splice(index, 1);
    newInboxAddresses.splice(index, 1);
    newInboxesValidity.splice(index, 1);

    setInboxes(newInboxes);
    setInboxAddresses(newInboxAddresses);
    setInboxesValidity(newInboxesValidity);
  }

  function createInboxes() {
    api("/api/inboxes/create-new", "POST", {
      username: user.username,
      inboxes: inboxAddresses,
    })
      .then((data) => {
        setEditInboxes(false);
        setInboxes([""]);
        setInboxAddresses([""]);

        toast({
          title: "Port created successfully",
          description: "Please, login again",
          status: "success",
          position: "top",
          duration: 9000,
          isClosable: true,
        });

        //LOGOUT
        handleLogout();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div style={{ marginBottom: "20px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "30px 0px",
          color: "#0059B3",
          fontWeight: "bold",
        }}
      >
        <Envelope size={30} />
        <div style={{ margin: "0px 10px", fontSize: "20px" }}>
          Port Configurations
        </div>
      </div>
      {userInboxes?.map((inbox, index) => {
        return <div className="inbox-value">{inbox}</div>;
      })}
      {editInboxes && (
        <div style={{ maxWidth: "500px" }}>
          <Divider />
          <ul
            style={{
              marginTop: "20px",
              fontWeight: "bold",
              color: "gray",
              fontStyle: "italic",
            }}
          >
            <li>You can have upto 5 ports.</li>
            <li>Port label should be of 2-15 characters.</li>
            <li>
              Port label should only have lowercase letters, numbers, hyphens -
              or underscores _ with first and last character only letter or
              number.
            </li>
            <li style={{ color: "#0059b3" }}>
              Example: If you create a port with the label as 'work', then the
              corresponding RelayBeam ID becomes '{`${user.username}`}@work'
            </li>
          </ul>
          {inboxes.map((inbox, index) => (
            <FormControl
              mt={6}
              isInvalid={inbox !== "" && !inboxesValidity[index]}
            >
              <FormLabel>{`Port #${
                index + 1
              } (Enter only port label)`}</FormLabel>
              <div style={{ display: "flex" }}>
                <Input
                  value={inbox}
                  onChange={(e) => handleInboxChange(index, e)}
                  className="inbox-input-field"
                />
                <IconButton
                  icon={<Dash />}
                  style={{ height: "auto" }}
                  isDisabled={index === 0 && inboxes.length === 1}
                  onClick={() => removeInbox(index)}
                />
              </div>
              {inbox !== "" && inboxesValidity[index] ? (
                <FormHelperText>{inboxAddresses[index]}</FormHelperText>
              ) : (
                <FormErrorMessage>Port label is invalid</FormErrorMessage>
              )}
            </FormControl>
          ))}
          <div className="inbox-config-buttons">
            <Button
              style={{
                backgroundColor: "#FF6200",
                color: "white",
                letterSpacing: "1px",
                margin: "20px 0px",
                fontSize: "15px",
              }}
              onClick={() => addInbox()}
              isDisabled={
                userInboxes.length + inboxes.length >= 5 ||
                inboxes[inboxes.length - 1] === ""
              }
            >
              ADD ANOTHER PORT
            </Button>
            <Button
              style={{
                padding: "20px 25px",
                backgroundColor: "#0059B3",
                color: "white",
                letterSpacing: "1px",
                margin: "20px 0px",
                fontSize: "15px",
              }}
              isDisabled={
                inboxes[inboxes.length - 1] === "" ||
                inboxes.includes("") ||
                userInboxes.length + inboxes.length > 5 ||
                inboxesValidity.includes(false)
              }
              onClick={() => createInboxes()}
            >
              CREATE
            </Button>
          </div>
        </div>
      )}
      {!editInboxes && (
        <Button
          style={{
            padding: "20px 25px",
            letterSpacing: "1px",
            margin: "20px 0px",
            fontSize: "15px",
          }}
          isDisabled={userInboxes.length >= 5}
          onClick={() => setEditInboxes(true)}
        >
          ADD PORT
        </Button>
      )}
    </div>
  );
}
