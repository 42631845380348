import React from "react";
import {
  Avatar,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import "./Notifications.css";
import { useUser } from "../contexts/UserProvider";
import { ThreeDotsVertical } from "react-bootstrap-icons";
import { useUsersInfo } from "../contexts/UsersInfoProvider";

export default function Notifications({ handleView, handleLogout }) {
  const { user } = useUser();
  const { usersInfo } = useUsersInfo();

  return (
    <div className="notifications-container">
      <div
        style={{
          display: "flex",
          backgroundColor: "#F0F0FF",
          padding: "10px 20px",
          borderRadius: "15px",
          width: "90%",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", maxWidth: "90%" }}>
          <Avatar size="sm" src={usersInfo[user.username]?.profilePictureV1} />
          <Text
            color="#666666"
            style={{
              paddingLeft: "12px",
              paddingTop: "5px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {user.full_name}
          </Text>
        </div>
        <Menu autoSelect={false} isLazy={true} placement="bottom-end">
          <MenuButton onClick={(e) => e.stopPropagation()}>
            <ThreeDotsVertical
              style={{
                marginLeft: "10px",
                flexShrink: "0",
              }}
            />
          </MenuButton>
          <MenuList
            style={{
              boxShadow:
                "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
            }}
          >
            <MenuItem onClick={() => handleView("settings")}>Settings</MenuItem>
            <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
          </MenuList>
        </Menu>
      </div>
    </div>
  );
}
