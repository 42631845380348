import React from "react";
import { Spinner } from "@chakra-ui/react";
import "./Loader.css";

export default function Loader() {
  return (
    <div className="loader">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="#FF6200"
        color="#0059B3"
        size="xl"
      />
    </div>
  );
}
