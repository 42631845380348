import React, { useEffect, useState } from "react";
import FileTypeIcon from "./FileTypeIcon";
import { useApi } from "../contexts/ApiProvider";
import Loader from "./loader/Loader";

export default function AttachmentsList({
  userInbox,
  threadId,
  directConversationId,
  messageMode,
}) {
  const { api } = useApi();

  const [attachments, setAttachments] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (messageMode === "multi") {
      setLoading(true);
      api("/api/attachments/get-attachments-by-thread", "POST", {
        user_inbox: userInbox,
        thread_id: threadId,
      })
        .then((data) => {
          setAttachments(data.attachments);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (messageMode === "single") {
      api("/api/attachments/get-attachments-by-direct-conversation", "POST", {
        user_inbox: userInbox,
        direct_conversation_id: directConversationId,
      })
        .then((data) => {
          setAttachments(data.attachments);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [threadId, directConversationId]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      {attachments &&
        attachments.length > 0 &&
        attachments.map((attachment, index) => {
          return (
            <div
              style={{
                display: "flex",
                margin: "5px 0px 20px 0px",
              }}
            >
              <FileTypeIcon
                fileType={attachment.attachment_mimetype}
                size={30}
                color="#0059B3"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  margin: "5px 5px 5px 10px",
                  fontSize: "15px",
                }}
              >
                {attachment.attachment_name}
              </div>
            </div>
          );
        })}
      {attachments && attachments.length === 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "gray",
          }}
        >
          No attachments
        </div>
      )}
    </div>
  );
}
