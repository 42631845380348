import React, { useContext, useRef, useState } from "react";
import { useApi } from "./ApiProvider";
import { useUsersInfo } from "./UsersInfoProvider";

const UserContext = React.createContext();

export function useUser() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [userInboxes, setUserInboxes] = useState(null);

  const [userInboxesReadStatus, _setUserInboxesReadStatus] = useState(null);

  const userInboxesReadStatusRef = useRef(null);

  const setUserInboxesReadStatus = (updatedUserInboxesReadStatus) => {
    userInboxesReadStatusRef.current = Object.assign(
      {},
      updatedUserInboxesReadStatus
    );
    _setUserInboxesReadStatus(updatedUserInboxesReadStatus);
  };

  const [userLoading, setUserLoading] = useState(false);
  const [userInboxesLoading, setUserInboxesLoading] = useState(false);

  const { api } = useApi();
  const { checkUserByUsername } = useUsersInfo();

  function getUser(username) {
    setUserLoading(true);
    api("/api/users/by-username", "POST", { username })
      .then((data) => {
        setUser(data.user);
        checkUserByUsername(username);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setUserLoading(false);
      });
  }

  function getUserInboxes(username) {
    setUserInboxesLoading(true);
    api("/api/inboxes/get-by-username", "POST", { username })
      .then((data) => {
        let inboxes = [];
        let inboxesReadStatus = {};
        data.inboxes.forEach((e) => {
          let inboxReadStatus = {};
          inboxReadStatus.thread_read = e.thread_read;
          inboxReadStatus.direct_conversation_read = e.direct_conversation_read;
          inboxes.push(e.relaybeam_id);
          inboxesReadStatus[e.relaybeam_id] = inboxReadStatus;
        });
        setUserInboxes(inboxes);
        setUserInboxesReadStatus(inboxesReadStatus);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setUserInboxesLoading(false);
      });
  }

  function markUserInboxStatusAsRead(inbox) {
    const newInboxObj = {};
    newInboxObj[inbox] = true;
    const updatedReadStatus = Object.assign(
      {},
      userInboxesReadStatus,
      newInboxObj
    );
    setUserInboxesReadStatus(updatedReadStatus);
  }

  function markUserInboxStatusAsUnread(inbox) {
    const newInboxObj = {};
    newInboxObj[inbox] = false;
    const updatedReadStatus = Object.assign(
      {},
      userInboxesReadStatus,
      newInboxObj
    );
    setUserInboxesReadStatus(updatedReadStatus);
  }

  function updateInboxThreadMode(inbox, read) {
    let newThreadReadObj = {};
    newThreadReadObj.thread_read = read;

    let userInboxReadObj = {};
    Object.assign(
      userInboxReadObj,
      userInboxesReadStatusRef.current[inbox],
      newThreadReadObj
    );

    let newInboxObj = {};
    newInboxObj[inbox] = userInboxReadObj;

    let updatedInboxesReadStatus = {};

    Object.assign(
      updatedInboxesReadStatus,
      userInboxesReadStatusRef.current,
      newInboxObj
    );

    setUserInboxesReadStatus(updatedInboxesReadStatus);

    if (read) {
      api("/api/inboxes/mark-multi-mode-as-read", "POST", { inbox })
        .then((data) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function updateInboxDirectConversationMode(inbox, read) {
    let newThreadReadObj = {};
    newThreadReadObj.direct_conversation_read = read;

    let userInboxReadObj = {};
    Object.assign(
      userInboxReadObj,
      userInboxesReadStatusRef.current[inbox],
      newThreadReadObj
    );

    let newInboxObj = {};
    newInboxObj[inbox] = userInboxReadObj;

    let updatedInboxesReadStatus = {};

    Object.assign(
      updatedInboxesReadStatus,
      userInboxesReadStatusRef.current,
      newInboxObj
    );

    setUserInboxesReadStatus(updatedInboxesReadStatus);

    if (read) {
      api("/api/inboxes/mark-single-mode-as-read", "POST", { inbox })
        .then((data) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        getUser,
        userInboxes,
        setUserInboxes,
        getUserInboxes,
        userInboxesReadStatus,
        markUserInboxStatusAsUnread,
        updateInboxThreadMode,
        updateInboxDirectConversationMode,
        userLoading,
        userInboxesLoading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
