import React from "react";
import { useSocket } from "../contexts/SocketProvider";
import Home from "./Home";
import { DataProvider } from "../contexts/DataProvider";

export default function HomeWrapper({ handleLogout }) {
  const socket = useSocket();

  return (
    <>
      {socket && (
        <DataProvider>
          <Home handleLogout={handleLogout} />
        </DataProvider>
      )}
    </>
  );
}
