import React from "react";
import { Flex, Text, Icon, Link, Menu, MenuButton } from "@chakra-ui/react";

export default function NavElement({
  icon,
  title,
  active,
  view,
  handleView,
  navSize,
}) {
  return (
    <Flex
      flexDir="column"
      w="100%"
      alignItems={navSize === "large" ? "flex-start" : "center"}
      style={{ marginTop: "10px" }}
    >
      <Menu placement="right">
        <Link
          backgroundColor={active && "#F0F0FF"}
          _hover={{
            textDecor: "none",
            backgroundColor: active ? "null" : "#F0F0FF",
          }}
          w={navSize === "large" && "100%"}
          style={{ padding: "10px 15px", borderRadius: "9px" }}
          onClick={() => handleView(view)}
        >
          <MenuButton w="100%">
            <Flex>
              <div>
                <Icon
                  as={icon}
                  fontSize="xl"
                  color={active ? "#0059B3" : "#505050"}
                  style={{ paddingTop: "2px" }}
                />
              </div>
              <Text
                display={navSize === "large" ? "flex" : "none"}
                color={active ? "#0059B3" : null}
                style={
                  active
                    ? {
                        marginLeft: "15px",
                        letterSpacing: "0.5px",
                        fontWeight: "bold",
                      }
                    : { marginLeft: "15px", letterSpacing: "0.5px" }
                }
              >
                <div>{title}</div>
              </Text>
            </Flex>
          </MenuButton>
        </Link>
      </Menu>
    </Flex>
  );
}
