import React from "react";
import "./PrivacyPolicy.css";
import { Link as ChakraLink } from "@chakra-ui/react";

export default function PrivacyPolicy() {
  return (
    <div className="privacy-policy-container">
      <div className="privacy-policy-heading">RELAYBEAM'S PRIVACY POLICY</div>
      <div className="privacy-policy-contents">
        <div className="privacy-policy-last-updated">
          Last Updated: April 09, 2024
        </div>
        <div className="privacy-policy-list-item">1. Introduction</div>
        <div className="privacy-policy-description">
          Welcome to RelayBeam! This Privacy Policy outlines how we collect,
          use, and protect your information when you use RelayBeam. By accessing
          and using the RelayBeam ("the App"), you signify your acceptance of
          this policy. If you do not agree to this policy, we recommend you to
          refrain from using the App.
        </div>
        <div className="privacy-policy-list-item">
          2. Information We Collect
        </div>
        <div className="privacy-policy-description">
          <div>
            a.{" "}
            <span style={{ fontWeight: "bold" }}>Personal Information: </span>We
            may collect personal information, such as but not limited to your
            name and date of birth, which may be provided by users for the
            purpose of user account creation and communication.
          </div>
          <div style={{ marginTop: "5px" }}>
            b. <span style={{ fontWeight: "bold" }}>Usage Data: </span>We may
            collect information on how you use the App, including features
            accessed and time spent on the platform, to improve our services.
          </div>
        </div>
        <div className="privacy-policy-list-item">
          3. How We Use Your Information
        </div>
        <div className="privacy-policy-description">
          <div>
            We utilize the collected data to enhance and optimize the App,
            ensuring an improved user experience.
          </div>
        </div>
        <div className="privacy-policy-list-item">4. Your rights</div>
        <div className="privacy-policy-description">
          You have the right to access and control your personal information.
          You can contact us at{" "}
          <span
            style={{
              color: "#0059B3",
              fontWeight: "bold",
            }}
          >
            <ChakraLink href="mailto:privacy@relaybeam.com">
              privacy@relaybeam.com
            </ChakraLink>
          </span>
        </div>
        <div className="privacy-policy-list-item">
          5. Changes to this Privacy Policy
        </div>
        <div className="privacy-policy-description">
          We may update this Privacy Policy to reflect changes in our data
          practices at any time. When we do, we will revise the updated date at
          the top of this page. We encourage Users to frequently check this page
          for any changes to stay informed about how we are helping to protect
          the personal information we collect. You acknowledge and agree that it
          is your responsibility to review this privacy policy periodically and
          become aware of modifications. Your continued use of the App following
          the posting of changes to this policy will be deemed your acceptance
          of those changes.
        </div>
        <div className="terms-of-service-list-item">6. Contact Information</div>
        <div className="terms-of-service-description">
          If you have any questions or concerns about this Privacy Policy,
          please contact us at{" "}
          <span
            style={{
              color: "#0059B3",
              fontWeight: "bold",
            }}
          >
            <ChakraLink href="mailto:privacy@relaybeam.com">
              privacy@relaybeam.com
            </ChakraLink>
          </span>
        </div>
      </div>
    </div>
  );
}
