import React, { useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { useAuth } from "./AuthProvider";

const SocketContext = React.createContext();

export function useSocket() {
  return useContext(SocketContext);
}

export function SocketProvider({ user, userInboxes, children }) {
  const [socket, setSocket] = useState(null);
  const { token } = useAuth();

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
      query: { userInboxes, username: user.username },
      auth: {
        token,
      },
    });
    setSocket(newSocket);

    return () => newSocket.close();
  }, [user.username]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
}
