import React, { useEffect, useState } from "react";
import Sidebar from "./layouts/Sidebar";
import "./Home.css";
import Contents from "./Contents";
import { useData } from "../contexts/DataProvider";
import Settings from "./Settings";
import { useApi } from "../contexts/ApiProvider";
import { useUser } from "../contexts/UserProvider";
import Files from "./files/Files";

export default function Home({ handleLogout }) {
  const { api } = useApi();
  const {
    inbox,
    setInbox,
    messageMode,
    setMessageMode,
    selectedThread,
    setSelectedThread,
    selectedDirectConversation,
    setSelectedDirectConversation,
    getThreads,
    getThreadMessages,
    markThreadAsReadInLocalState,
    updateLastReadThreadMessage,
    threads,
    messages,
    getDirectConversations,
    getDirectConversationMessages,
    markDirectConversationAsReadInLocalState,
    directConversationMessages,
    updateLastReadDirectConversationMessage,
  } = useData();
  const {
    userInboxesReadStatus,
    updateInboxThreadMode,
    updateInboxDirectConversationMode,
  } = useUser();

  const [navSize, setNavSize] = useState(
    window.innerWidth > 1024 ? "large" : "small"
  );

  const [view, setView] = useState("messages");

  useEffect(() => {
    if (selectedThread && !selectedThread.thread_read) {
      markThreadAsRead(selectedThread.thread_id);
    }
  }, [threads]);

  function handleView(view) {
    setView(view);
  }

  function handleInbox(inbox) {
    setInbox(inbox);
    handleThread(null);
    handleDirectConversation(null);
    getThreads(inbox);
    getDirectConversations(inbox);

    if (messageMode === "multi") {
      if (!userInboxesReadStatus[inbox].thread_read) {
        updateInboxThreadMode(inbox, true);
      }
    } else if (messageMode === "single") {
      if (!userInboxesReadStatus[inbox].direct_conversation_read) {
        updateInboxDirectConversationMode(inbox, true);
      }
    }
  }

  function handleMessageMode(mode) {
    setMessageMode(mode);
    if (mode === "multi") {
      if (!userInboxesReadStatus[inbox].thread_read) {
        updateInboxThreadMode(inbox, true);
      }
    } else if (mode === "single") {
      if (!userInboxesReadStatus[inbox].direct_conversation_read) {
        updateInboxDirectConversationMode(inbox, true);
      }
    }
  }

  function handleThread(thread) {
    if (
      selectedThread &&
      messages &&
      messages[selectedThread?.thread_id]?.length > 0
    ) {
      const lastReadMessageId =
        messages[selectedThread.thread_id][0].message_id;
      if (lastReadMessageId !== selectedThread.last_read_message_id) {
        updateLastReadThreadMessage(
          inbox,
          selectedThread.thread_id,
          lastReadMessageId
        );
      }
    }

    //-----------------------------Before setting the new thread, marking the last read message of previous thread------------------------//

    setSelectedThread(thread);

    if (thread) {
      if (!messages.hasOwnProperty(thread.thread_id)) {
        getThreadMessages(thread, inbox);
      }
      if (!thread.thread_read) {
        markThreadAsRead(thread.thread_id);
      }
    }
  }

  function handleDirectConversation(directConversation) {
    if (
      selectedDirectConversation &&
      directConversationMessages &&
      directConversationMessages[
        selectedDirectConversation?.direct_conversation_id
      ]?.length > 0
    ) {
      const lastReadMessageId =
        directConversationMessages[
          selectedDirectConversation.direct_conversation_id
        ][0].message_id;
      if (
        lastReadMessageId !== selectedDirectConversation.last_read_message_id
      ) {
        updateLastReadDirectConversationMessage(
          inbox,
          selectedDirectConversation.direct_conversation_id,
          lastReadMessageId
        );
      }
    }

    //-----------------------------Before setting the new direct conversation, marking the last read message of previous direct conversation------------------------//

    setSelectedDirectConversation(directConversation);
    if (directConversation) {
      if (
        !directConversationMessages.hasOwnProperty(
          directConversation.direct_conversation_id
        )
      ) {
        getDirectConversationMessages(
          inbox,
          directConversation.direct_conversation_id
        );
      }
      if (!directConversation.conversation_read) {
        markDirectConversationAsRead(directConversation.direct_conversation_id);
      }
    }
  }

  function markThreadAsRead(threadId) {
    api("/api/threads/mark-as-read", "POST", {
      inbox,
      thread_id: threadId,
    })
      .then((data) => {
        markThreadAsReadInLocalState(inbox, threadId);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function markDirectConversationAsRead(directConversationId) {
    api("/api/direct-conversations/mark-as-read", "POST", {
      user_inbox: inbox,
      direct_conversation_id: directConversationId,
    })
      .then((data) => {
        markDirectConversationAsReadInLocalState(inbox, directConversationId);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="home-container">
      <div className="sidebar-container">
        <Sidebar
          selectedInbox={inbox}
          handleThread={handleThread}
          handleDirectConversation={handleDirectConversation}
          view={view}
          handleView={handleView}
          messageMode={messageMode}
          handleLogout={handleLogout}
          navSize={navSize}
          setNavSize={setNavSize}
        />
      </div>
      <div className="right-section">
        {view === "messages" && (
          <Contents
            handleInbox={handleInbox}
            selectedInbox={inbox}
            handleThread={handleThread}
            selectedThread={selectedThread}
            handleDirectConversation={handleDirectConversation}
            selectedDirectConversation={selectedDirectConversation}
            messageMode={messageMode}
            handleMessageMode={handleMessageMode}
            navSize={navSize}
            handleView={handleView}
            handleLogout={handleLogout}
          />
        )}
        {view === "files" && (
          <div
            style={
              navSize === "large"
                ? { width: "calc(100vw - 200px)" }
                : { width: "calc(100vw - 75px" }
            }
          >
            <Files />
          </div>
        )}
        {view === "settings" && <Settings handleLogout={handleLogout} />}
      </div>
    </div>
  );
}
