import React, { useContext, useEffect, useRef, useState } from "react";
import { useApi } from "./ApiProvider";

const UsersInfoContext = React.createContext();

export function useUsersInfo() {
  return useContext(UsersInfoContext);
}

export function UsersInfoProvider({ children }) {
  const [usersInfo, setUsersInfo] = useState({});

  const { api } = useApi();

  function checkUserByUsername(username) {
    // single user (username) is passed
    if (!usersInfo.hasOwnProperty(username)) {
      getUserInfo(username);
    }
  }

  function checkUserByInbox(userInbox) {
    // single user (inbox) is passed
    const username = userInbox.substring(0, userInbox.indexOf("@"));

    // single user (username) is passed
    if (!usersInfo.hasOwnProperty(username)) {
      getUserInfo(username);
    }
  }

  function checkUsersInfoByInbox(userInboxes) {
    // users inboxes is passed as array

    const users = [];

    userInboxes.forEach((userInbox, index) => {
      users[index] = userInbox.substring(0, userInbox.indexOf("@"));
    });

    for (let i = 0; i < users.length; i++) {
      if (!usersInfo.hasOwnProperty(users[i])) {
        getUserInfo(users[i]);
      }
    }
  }

  function getUserInfo(username) {
    api("/api/users/get-info", "POST", { username })
      .then(async (data) => {
        let objectURL = null;

        if (data.profilePicURL !== null) {
          const profilePicURL = data.profilePicURL;
          const response = await fetch(profilePicURL);
          const blob = await response.blob();
          objectURL = URL.createObjectURL(blob);
        }
        const newUserInfo = {
          fullName: data.fullName,
          profilePictureV1: objectURL,
          profilePictureV2: null,
        };

        const newUserObj = {};
        newUserObj[username] = { ...newUserInfo };
        setUsersInfo((usersInfo) => ({
          ...usersInfo,
          ...newUserObj,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <UsersInfoContext.Provider
      value={{
        usersInfo,
        checkUserByUsername,
        checkUserByInbox,
        checkUsersInfoByInbox,
        getUserInfo,
      }}
    >
      {children}
    </UsersInfoContext.Provider>
  );
}
