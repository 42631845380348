import { format, parseISO } from "date-fns";

export function dateTimeParser(timestamp) {
  const currDate = new Date();
  const prevDate = new Date();
  prevDate.setDate(currDate.getDate() - 1);

  const timestampDate = new Date(timestamp);

  if (
    currDate.getDate() === timestampDate.getDate() &&
    currDate.getMonth() === timestampDate.getMonth() &&
    currDate.getFullYear() === timestampDate.getFullYear()
  ) {
    return `Today at ${timestampDate.getHours()}:${timestampDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  } else if (
    prevDate.getDate() === timestampDate.getDate() &&
    prevDate.getMonth() === timestampDate.getMonth() &&
    prevDate.getFullYear() === timestampDate.getFullYear()
  ) {
    return `Yesterday at ${timestampDate.getHours()}:${timestampDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  } else {
    return `${timestampDate.getDate()}/${
      timestampDate.getMonth() + 1
    }/${timestampDate.getFullYear()} ${timestampDate.getHours()}:${timestampDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  }
}

export function dayTimeParser(timestamp) {
  const currDate = new Date();
  const prevDate = new Date();
  prevDate.setDate(currDate.getDate() - 1);

  const timestampDate = new Date(timestamp);

  if (
    currDate.getDate() === timestampDate.getDate() &&
    currDate.getMonth() === timestampDate.getMonth() &&
    currDate.getFullYear() === timestampDate.getFullYear()
  ) {
    return `${timestampDate.getHours()}:${timestampDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  } else if (
    prevDate.getDate() === timestampDate.getDate() &&
    prevDate.getMonth() === timestampDate.getMonth() &&
    prevDate.getFullYear() === timestampDate.getFullYear()
  ) {
    return `Yesterday`;
  } else {
    return `${timestampDate.getDate()}/${
      timestampDate.getMonth() + 1
    }/${timestampDate.getFullYear()}`;
  }
}

export function checkIfDatesAreEqual(timestamp1, timestamp2) {
  const date1 = new Date(timestamp1);
  const date2 = new Date(timestamp2);

  if (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  ) {
    return true;
  } else {
    return false;
  }
}

export function parseTimeStampToDate(timestamp) {
  const parsedDate = parseISO(timestamp);
  const parsedTimeStampToDate = format(parsedDate, "PPP");
  return parsedTimeStampToDate;
}

export function parseTimeStampToDateShort(timestamp) {
  const parsedDate = parseISO(timestamp);
  const parsedTimeStampToDate = format(parsedDate, "PP");
  return parsedTimeStampToDate;
}

export function timeParser(timestamp) {
  const timestampDate = new Date(timestamp);

  return `${timestampDate.getHours()}:${timestampDate
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;
}

export function dateParser(timestamp) {
  const currDate = new Date();
  const prevDate = new Date();
  prevDate.setDate(currDate.getDate() - 1);

  const timestampDate = new Date(timestamp);

  const parsedDate = parseISO(timestamp);
  const parsedTimeStampToDate = format(parsedDate, "PPP");

  if (
    currDate.getDate() === timestampDate.getDate() &&
    currDate.getMonth() === timestampDate.getMonth() &&
    currDate.getFullYear() === timestampDate.getFullYear()
  ) {
    return `Today`;
  } else if (
    prevDate.getDate() === timestampDate.getDate() &&
    prevDate.getMonth() === timestampDate.getMonth() &&
    prevDate.getFullYear() === timestampDate.getFullYear()
  ) {
    return `Yesterday`;
  } else {
    return `${parsedTimeStampToDate}`;
  }
}

export function dateParserShort(timestamp) {
  const currDate = new Date();
  const prevDate = new Date();
  prevDate.setDate(currDate.getDate() - 1);

  const timestampDate = new Date(timestamp);

  const parsedDate = parseISO(timestamp);
  const parsedTimeStampToDate = format(parsedDate, "PP");

  if (
    currDate.getDate() === timestampDate.getDate() &&
    currDate.getMonth() === timestampDate.getMonth() &&
    currDate.getFullYear() === timestampDate.getFullYear()
  ) {
    return `Today`;
  } else if (
    prevDate.getDate() === timestampDate.getDate() &&
    prevDate.getMonth() === timestampDate.getMonth() &&
    prevDate.getFullYear() === timestampDate.getFullYear()
  ) {
    return `Yesterday`;
  } else {
    return `${parsedTimeStampToDate}`;
  }
}
