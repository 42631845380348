import React, { useState } from "react";
import "./UserProfileInactive.css";
import AddInbox from "./AddInbox";
import UserProfilePic from "./UserProfilePic";

export default function UserProfileInactive({ handleLogout }) {
  const [nextPage, setNextPage] = useState("add-inbox");

  function handleNextPage(nextPageView) {
    setNextPage(nextPageView);
  }

  return (
    <div className="user-profile-inactive-container">
      {nextPage === "add-inbox" && (
        <AddInbox handleLogout={handleLogout} handleNextPage={handleNextPage} />
      )}
      {nextPage === "user-profile-pic" && (
        <UserProfilePic handleNextPage={handleNextPage} />
      )}
    </div>
  );
}
