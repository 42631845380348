import React, { useState } from "react";
import Login from "./Login";
import "./InitialView.css";

export default function InitialView() {
  const [view, setView] = useState("login");

  return (
    <div className="intial-view-container">
      {view === "login" && <Login setView={setView} />}
    </div>
  );
}
