import React, { useRef, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  useDisclosure,
  useToast,
  Avatar,
} from "@chakra-ui/react";
import { useUser } from "../../contexts/UserProvider";
import { useApi } from "../../contexts/ApiProvider";
import imageCompression from "browser-image-compression";
import { randomAlphaNumeric } from "../../utils/utils";
import { useUsersInfo } from "../../contexts/UsersInfoProvider";

export default function EditProfilePic() {
  const { user, getUser } = useUser();
  const { usersInfo, getUserInfo } = useUsersInfo();
  const { api } = useApi();
  const toast = useToast();

  const [image, setImage] = useState(null);
  const [compressedImage1, setCompressedImage1] = useState(null);
  const [compressedImage2, setCompressedImage2] = useState(null);
  const [isImage1Loading, setIsImage1Loading] = useState(false);
  const [isImage2Loading, setIsImage2Loading] = useState(false);

  const fileInputRef = useRef();

  const {
    isOpen: isEditProfilePicOpen,
    onOpen: onEditProfilePicOpen,
    onClose: onEditProfilePicClose,
  } = useDisclosure();

  function handleUploadButtonClick() {
    fileInputRef.current.click();
  }

  function handleFileInputChange(e) {
    if (e.target.files && e.target.files[0]) {
      const uploadedImage = e.target.files[0];
      setImage(uploadedImage);

      compressImage1(uploadedImage);
      compressImage2(uploadedImage);
    }
  }

  async function compressImage1(file) {
    const options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1024,
      useWebWorker: false,
    };

    const outputImage = await imageCompression(file, options);
    setCompressedImage1(outputImage);
  }

  async function compressImage2(file) {
    const options = {
      maxSizeMB: 0.3,
      maxWidthOrHeight: 1024,
      useWebWorker: false,
    };

    const outputImage = await imageCompression(file, options);
    setCompressedImage2(outputImage);
  }

  function handleSetProfilePicture() {
    const fileExt = image.name.split(".").pop();
    const randomKey = randomAlphaNumeric(8);

    const objName = user.username + "_" + randomKey + "." + fileExt;
    const objKey1 = `users/profile/picture/s1/${objName}`;
    const objKey2 = `users/profile/picture/s2/${objName}`;

    setIsImage1Loading(true);
    setIsImage2Loading(true);

    api("/api/users/upload-profile-pic", "POST", {
      objKey1,
      objKey2,
      objName: objName,
      username: user.username,
    })
      .then(async (data) => {
        await fetch(data.preSignedUrl1, {
          method: "PUT",
          body: compressedImage1,
          mode: "cors",
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("File upload failed");
            }
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
          })
          .finally(() => {
            setIsImage1Loading(false);
          });

        await fetch(data.preSignedUrl2, {
          method: "PUT",
          body: compressedImage2,
          mode: "cors",
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("File upload failed");
            }
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
          })
          .finally(() => {
            setIsImage2Loading(false);
          });
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      })
      .finally(() => {
        getUser(user.username);
        getUserInfo(user.username);
        handleEditProfilePicClose();
        toast({
          title: "Profile picture updated successfully",
          status: "success",
          position: "top",
          duration: 9000,
          isClosable: true,
        });
        setImage(null);
        setCompressedImage1(null);
        setCompressedImage2(null);
        setIsImage1Loading(false);
        setIsImage2Loading(false);
      });
  }

  function handleEditProfilePicClose() {
    onEditProfilePicClose();
    setImage(null);
    setCompressedImage1(null);
    setCompressedImage2(null);
    setIsImage1Loading(false);
    setIsImage2Loading(false);
  }

  return (
    <div>
      <div
        style={{
          margin: "10px 0px",
          width: "fit-content",
          textAlign: "center",
        }}
      >
        <div>
          <Avatar size="2xl" src={usersInfo[user.username]?.profilePictureV1} />
        </div>
        <div>
          <Button
            style={{
              padding: "20px 25px",
              letterSpacing: "1px",
              margin: "20px 0px",
              fontSize: "15px",
            }}
            onClick={() => onEditProfilePicOpen()}
          >
            EDIT PROFILE PICTURE
          </Button>
        </div>
      </div>

      <Modal isOpen={isEditProfilePicOpen} onClose={handleEditProfilePicClose}>
        <ModalOverlay />
        <ModalContent style={{ borderRadius: "15px", paddingBottom: "30px" }}>
          <ModalHeader>Edit Profile Picture</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              {!image && <Avatar size="2xl" />}
              {image && <Avatar size="2xl" src={URL.createObjectURL(image)} />}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  id="upload-button"
                  ref={fileInputRef}
                  onChange={handleFileInputChange}
                />
                {image && (
                  <Button
                    style={{
                      backgroundColor: "#0059B3",
                      color: "white",
                      margin: "30px 25px 10px 25px",
                    }}
                    mt={4}
                    isLoading={
                      !(compressedImage1 && compressedImage2) ||
                      isImage1Loading ||
                      isImage2Loading
                    }
                    onClick={() => handleSetProfilePicture()}
                  >
                    Set Profile Picture
                  </Button>
                )}
                <label
                  htmlFor="upload-button"
                  style={
                    image
                      ? { margin: "10px 25px" }
                      : { margin: "30px 25px 10px 25px" }
                  }
                >
                  <Button
                    style={
                      image
                        ? { width: "100%" }
                        : {
                            backgroundColor: "#0059B3",
                            color: "white",
                            width: "100%",
                          }
                    }
                    onClick={() => handleUploadButtonClick()}
                  >
                    {image ? "Upload other picture" : "Upload"}
                  </Button>
                </label>
                <Button
                  style={{ margin: "10px 25px" }}
                  onClick={() => handleEditProfilePicClose()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
