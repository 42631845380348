export async function silentRefresh() {
  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/api/auth/refresh_token",
      {
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        credentials: "include",
      }
    );

    if (!response.ok) throw new Error(`HTTP Error: Status ${response.status}`);

    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    console.error(`${error}`);
  }
}
