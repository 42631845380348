import React from "react";
import { useData } from "../../contexts/DataProvider";
import "./DirectConversations.css";
import { dateParserShort } from "../../utils/date-time-parser";
// import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
// import { ThreeDots } from "react-bootstrap-icons";
import Loader from "../loader/Loader";
import { SlideFade } from "@chakra-ui/react";

export default function DirectConversations({
  selectedInbox,
  handleDirectConversation,
  selectedDirectConversation,
}) {
  const { directConversations, directConversationsLoading } = useData();

  if (directConversationsLoading) {
    return <Loader />;
  }

  return (
    <>
      {directConversations &&
        directConversations[selectedInbox]?.map((conversation, index) => {
          return (
            <SlideFade in={true}>
              <div
                className={
                  selectedDirectConversation
                    ? conversation.direct_conversation_id ===
                      selectedDirectConversation.direct_conversation_id
                      ? "selected-direct-conversation"
                      : conversation.conversation_read
                      ? "direct-conversation"
                      : "unread-direct-conversation"
                    : conversation.conversation_read
                    ? "direct-conversation"
                    : "unread-direct-conversation"
                }
                onClick={() => handleDirectConversation(conversation)}
                key={conversation.direct_conversation_id}
              >
                <div className="direct-conversation-recipient-container">
                  <div className="direct-conversation-recipient">{`${conversation.conversation_recipient}`}</div>
                  <div className="direct-conversation-modified-time">
                    {dateParserShort(conversation.modified_at)}
                  </div>
                </div>
                <div className="latest-direct-conversation-message">
                  <div className="sender-container">
                    <div className="sender-inbox-label">{`${conversation.message_sender}:`}</div>
                    {!conversation.conversation_read && (
                      <span className="dot" />
                    )}
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="direct-conversation-message-content">
                      {conversation.message}
                    </div>
                    {/* <Menu autoSelect={false} isLazy={true} placement="bottom-end">
                    <MenuButton onClick={(e) => e.stopPropagation()}>
                      <ThreeDots />
                    </MenuButton>
                    <MenuList>
                      <MenuItem>Delete Conversation</MenuItem>
                    </MenuList>
                  </Menu> */}
                  </div>
                </div>
              </div>
            </SlideFade>
          );
        })}
    </>
  );
}
