import React from "react";
import "./TermsOfService.css";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";

export default function TermsOfService() {
  return (
    <div className="terms-of-service-container">
      <div className="terms-of-service-heading">
        RELAYBEAM'S TERMS OF SERVICE
      </div>
      <div className="terms-of-service-contents">
        <div className="terms-of-service-last-updated">
          Last Updated: April 09, 2024
        </div>
        <div className="terms-of-service-list-item">1. Acceptance of Terms</div>
        <div className="terms-of-service-description">
          By accessing and using the RelayBeam ("the App"), you agree to comply
          with and be bound by these Terms of Service. If you do not agree with
          any part of these terms, we recommend you to refrain from using the
          App.
        </div>
        <div className="terms-of-service-list-item">
          2. Beta Version Disclaimer
        </div>
        <div className="terms-of-service-description">
          The App is provided as a beta version, and as such, may contain
          errors, bugs, or other issues. By using the App, you acknowledge and
          accept that it is provided on an "as-is" and "as-available" basis.
        </div>
        <div className="terms-of-service-list-item">
          3. User Responsibilities
        </div>
        <div className="terms-of-service-description">
          <div>
            a. <span style={{ fontWeight: "bold" }}>Feedback: </span>We
            encourage users to provide feedback on their experiences, report
            bugs, and suggest improvements.
          </div>
          <div style={{ marginTop: "5px" }}>
            b. <span style={{ fontWeight: "bold" }}>Data Loss: </span>Given that
            the App is in its beta version, there is a risk of data loss. Users
            are encouraged to exercise caution when using the App, particularly
            with regard to critical data.
          </div>
        </div>
        <div className="terms-of-service-list-item">
          4. Intellectual Property
        </div>
        <div className="terms-of-service-description">
          All intellectual property rights related to the App, including but not
          limited to trademarks, copyrights, and patents, are the property of
          Suprit Gandhi, who is the sole developer and creator of RelayBeam.
        </div>
        <div className="terms-of-service-list-item">5. Termination</div>
        <div className="terms-of-service-description">
          We reserve the right to terminate, suspend, or modify the App at any
          time without notice. We may also restrict or terminate your access if
          we believe that you have violated these Terms of Service.
        </div>
        <div className="terms-of-service-list-item">6. Privacy</div>
        <div className="terms-of-service-description">
          Your use of the App is also governed by our Privacy Policy, which can
          be found{" "}
          <ChakraLink
            as={ReactRouterLink}
            to="/privacy"
            color="#0059B3"
            style={{ fontWeight: "bold" }}
          >
            here
          </ChakraLink>
          .
        </div>
        <div className="terms-of-service-list-item">
          7. Limitation of Liability
        </div>
        <div className="terms-of-service-description">
          To the maximum extent permitted by law, Suprit Gandhi, who is the sole
          developer and creator of RelayBeam shall not be liable for any direct,
          indirect, incidental, special, consequential, or exemplary damages,
          including but not limited to, damages for loss of profits, goodwill,
          use, data, or other intangible losses resulting from the use or
          inability to use the App.
        </div>
        <div className="terms-of-service-list-item">8. Changes to Terms</div>
        <div className="terms-of-service-description">
          We reserve the right to update or modify these Terms of Service at any
          time. When we do, we will revise the updated date at the top of this
          page. It is your responsibility to review these terms periodically.
          Your continued use of the App after any changes constitutes acceptance
          of those changes.
        </div>
        <div className="terms-of-service-list-item">9. Contact Information</div>
        <div className="terms-of-service-description">
          If you have any questions or concerns about these Terms of Service,
          please contact us at{" "}
          <span
            style={{
              color: "#0059B3",
              fontWeight: "bold",
            }}
          >
            <ChakraLink href="mailto:privacy@relaybeam.com">
              privacy@relaybeam.com
            </ChakraLink>
          </span>
        </div>
      </div>
    </div>
  );
}
