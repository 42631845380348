import React, { useContext } from "react";
import { useApi } from "./ApiProvider";
import { saveAs } from "file-saver";
import { useToast } from "@chakra-ui/react";

const FileContext = React.createContext();

export function useFile() {
  return useContext(FileContext);
}

export function FileProvider({ children }) {
  const { api } = useApi();
  const toast = useToast();

  function downloadFile(fileKey, fileName) {
    api("/api/files/download", "POST", { objKey: fileKey })
      .then((data) => {
        fetch(data.preSignedUrl)
          .then((res) => {
            if (res.ok) {
              return res.blob();
            } else {
              toast({
                title: "File does not exist",
                description: "Sender has deleted the file",
                status: "error",
                position: "top",
                duration: 9000,
                isClosable: true,
              });
              return Promise.reject(res);
            }
          })
          .then((blob) => {
            saveAs(blob, fileName);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <FileContext.Provider value={{ downloadFile }}>
      {children}
    </FileContext.Provider>
  );
}
