import React, { useState } from "react";
import "./Compose.css";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Textarea,
  FormErrorMessage,
  FormHelperText,
  Spinner,
} from "@chakra-ui/react";
import { SendFill } from "react-bootstrap-icons";
import { useApi } from "../../contexts/ApiProvider";
import { useSocket } from "../../contexts/SocketProvider";
import { useData } from "../../contexts/DataProvider";

export default function ComposeSingle({
  selectedInbox,
  handleDirectConversation,
  isOpen,
  onClose,
}) {
  const { api } = useApi();
  const socket = useSocket();
  const { sendDirectConversationMessage } = useData();

  const [recipient, setRecipient] = useState("");
  const [message, setMessage] = useState(null);

  const [recipientCheck, setRecipientCheck] = useState(false);
  const [recipientCheckLoading, setRecipientCheckLoading] = useState(false);
  const [recipientInvalid, setRecipientInvalid] = useState(false);
  const [recipientInvalidMsg, setRecipientInvalidMsg] = useState("");

  function handleComposeSingle() {
    api("/api/compose-single/create-by-user-inbox", "POST", {
      message,
      sender: selectedInbox,
      recipient,
    })
      .then((data) => {
        handleDirectConversation(data);

        socket.emit("send-direct-message", {
          directConversationId: data.direct_conversation_id,
          sender: selectedInbox,
          recipient,
          message,
          messageId: data.last_read_message_id,
          hasAttachment: false,
          attachmentObj: {},
        });

        sendDirectConversationMessage({
          directConversationId: data.direct_conversation_id,
          conversationRecipient: recipient,
          userInbox: selectedInbox,
          recipient,
          message,
          messageId: data.last_read_message_id,
          sender: selectedInbox,
          hasAttachment: false,
          attachmentObj: {},
        });

        onClose();

        setRecipient("");
        setMessage("");
        setRecipientCheck(false);
        setRecipientInvalid(false);
        setRecipientInvalidMsg("");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleRecipient(recipient) {
    setRecipientCheck(false);
    setRecipient(recipient.replace(/\s/g, ""));
  }

  function handleRecipientInputBlur() {
    if (recipient !== "") {
      setRecipientCheckLoading(true);
      if (recipient === selectedInbox) {
        setRecipientInvalid(true);
        setRecipientInvalidMsg("Can not add self as recipient");
        setRecipientCheck(true);
        setRecipientCheckLoading(false);
      } else {
        api("/api/inboxes/verify-if-exists", "POST", {
          relaybeam_id: recipient,
        })
          .then((data) => {
            if (data.exists) {
              api("/api/direct-conversations/verify-if-exists", "POST", {
                user_inbox: selectedInbox,
                recipient: recipient,
              })
                .then((data) => {
                  if (data.exists) {
                    setRecipientInvalid(true);
                    setRecipientInvalidMsg(
                      "Direct conversation with recipient already exists"
                    );
                    setRecipientCheck(true);
                    setRecipientCheckLoading(false);
                  } else {
                    setRecipientInvalid(false);
                    setRecipientInvalidMsg("");
                    setRecipientCheck(true);
                    setRecipientCheckLoading(false);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              setRecipientInvalid(true);
              setRecipientInvalidMsg("Recipient does not exist");
              setRecipientCheck(true);
              setRecipientCheckLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent style={{ borderRadius: "20px" }}>
        <ModalHeader>Compose</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          style={{
            paddingBottom: "6px",
          }}
        >
          <FormControl isInvalid={recipientCheck && recipientInvalid}>
            <FormLabel>To</FormLabel>
            <div className="add-recipient-container">
              <div className="add-recipient-input">
                <Input
                  placeholder="Add recipient"
                  value={recipient}
                  onChange={(e) => handleRecipient(e.currentTarget.value)}
                  onBlur={(e) => handleRecipientInputBlur()}
                />

                {recipientCheck && recipientInvalid && (
                  <FormErrorMessage>{recipientInvalidMsg}</FormErrorMessage>
                )}
                {recipientCheck && !recipientInvalid && (
                  <FormHelperText style={{ color: "green" }}>
                    Recipient Verified
                  </FormHelperText>
                )}
                {!recipientCheck &&
                  recipientCheckLoading &&
                  recipient !== "" && (
                    <div
                      style={{
                        display: "flex",
                        margin: "5px",
                        alignItems: "center",
                      }}
                    >
                      <Spinner
                        thickness="2px"
                        speed="0.65s"
                        emptyColor="#FF6200"
                        color="#0059B3"
                        size="sm"
                        style={{ marginRight: "5px" }}
                      />
                      <div>Verifying...</div>
                    </div>
                  )}
              </div>
            </div>
          </FormControl>
          <FormControl style={{ marginTop: "15px" }}>
            <FormLabel>Message</FormLabel>
            <Textarea
              style={{ width: "100%" }}
              placeholder="Write a message..."
              value={message}
              onChange={(e) => setMessage(e.currentTarget.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            style={{ margin: "7px 10px" }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleComposeSingle()}
            style={{
              backgroundColor: "#0059B3",
              color: "white",
              margin: "7px 10px",
            }}
            isDisabled={!recipientCheck || recipientInvalid || !message}
            rightIcon={<SendFill style={{ marginBottom: "2px" }} />}
          >
            Send
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
