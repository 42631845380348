import React from "react";
import { FileEarmark } from "react-bootstrap-icons";

export default function FileTypeIcon({ fileType, size, color }) {
  function renderFileTypeIcon() {
    return <FileEarmark size={size} color={color} />;
  }

  return <>{renderFileTypeIcon()}</>;
}
