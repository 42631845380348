import React, { useEffect } from "react";
import { Avatar } from "@chakra-ui/react";
import "./ThreadParticipants.css";
import { useUsersInfo } from "../contexts/UsersInfoProvider";

export default function ThreadParticipants({ threadId, threadParticipants }) {
  const { usersInfo, checkUsersInfoByInbox } = useUsersInfo();

  useEffect(() => {
    checkUsersInfoByInbox(threadParticipants);
  }, [threadId]);

  return (
    <div>
      {threadParticipants &&
        threadParticipants.map((participant, index) => {
          const participantUserName = participant.substring(
            0,
            participant.indexOf("@")
          );

          return (
            <div className={index === 0 ? "first-participant" : "participants"}>
              <Avatar
                size="sm"
                src={usersInfo[participantUserName]?.profilePictureV1}
                key={participant}
              />
              <div className="participant-label">
                {usersInfo && usersInfo[participantUserName]?.fullName && (
                  <div>{usersInfo[participantUserName].fullName}</div>
                )}
                <div style={{ color: "gray" }}>{participant}</div>
              </div>
            </div>
          );
        })}
    </div>
  );
}
