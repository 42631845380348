import React, { useContext } from "react";
import { useToast } from "@chakra-ui/react";
import { useAuth } from "./AuthProvider";

const ApiContext = React.createContext();

export function useApi() {
  return useContext(ApiContext);
}

export function ApiProvider({ children }) {
  const { token, setToken } = useAuth();
  const toast = useToast();

  function logoutUser() {
    fetch(process.env.REACT_APP_API_URL + "/api/auth/refresh_token", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) return response.json();
        throw new Error(`HTTP Error: Status ${response.status}`);
      })
      .then((data) => {
        setToken(null);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const api = (path, method, body) => {
    return new Promise((resolve, reject) => {
      const payload = {
        method,
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        mode: "cors",
        credentials: "include",
      };
      if (body !== null) {
        payload.body = JSON.stringify(body);
      }

      fetch(process.env.REACT_APP_API_URL + path, payload)
        .then((response) => {
          if (response.ok) {
            resolve(response.json());
          } else {
            fetch(process.env.REACT_APP_API_URL + "/api/auth/refresh_token", {
              headers: {
                "Content-Type": "application/json",
              },
              mode: "cors",
              credentials: "include",
            })
              .then((response) => {
                if (response.ok) return response.json();
                throw new Error(`HTTP Error: Status ${response.status}`);
              })
              .then((data) => {
                setToken(data.accessToken);
                payload.headers.Authorization = "Bearer " + data.accessToken;
                fetch(process.env.REACT_APP_API_URL + path, payload).then(
                  (response) => {
                    if (response.ok) {
                      resolve(response.json());
                    } else {
                      logoutUser();
                      toast({
                        title: "Session timeout",
                        description: "To get back in, just login again",
                        status: "error",
                        position: "top",
                        duration: 9000,
                        isClosable: true,
                      });
                      reject("logout");
                    }
                  }
                );
              })
              .catch((err) => {
                logoutUser();
                toast({
                  title: "Seomthing went wrong",
                  description: "Please, try again",
                  status: "error",
                  position: "top",
                  duration: 9000,
                  isClosable: true,
                });
                reject(err);
              });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return <ApiContext.Provider value={{ api }}>{children}</ApiContext.Provider>;
}
