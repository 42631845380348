import React from "react";
import ComposeSingle from "./ComposeSingle";
import ComposeMulti from "./ComposeMulti";

export default function Compose({
  selectedInbox,
  handleThread,
  handleDirectConversation,
  messageMode,
  isOpen,
  onClose,
}) {
  return (
    <>
      {messageMode === "single" && (
        <ComposeSingle
          selectedInbox={selectedInbox}
          handleDirectConversation={handleDirectConversation}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
      {messageMode === "multi" && (
        <ComposeMulti
          selectedInbox={selectedInbox}
          handleThread={handleThread}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
}
