import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Textarea,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";
import "./Compose.css";
import { DashCircle, EnvelopePlus, SendFill } from "react-bootstrap-icons";
import { useSocket } from "../../contexts/SocketProvider";
import { useApi } from "../../contexts/ApiProvider";

export default function ComposeMulti({
  selectedInbox,
  handleThread,
  isOpen,
  onClose,
}) {
  const [recipientsList, setRecipientsList] = useState([]);
  const [recipientsValidityObj, setRecipientsValidityObj] = useState({});
  const [topic, setTopic] = useState(null);
  const [message, setMessage] = useState(null);
  const [recipientInput, setRecipientInput] = useState(null);
  const [recipientInputExists, setRecipientInputExists] = useState(false);
  const [recipientSenderError, setRecipientSenderError] = useState(false);

  const socket = useSocket();
  const { api } = useApi();

  function handleComposeMulti() {
    const participants = [...recipientsList];
    participants.push(selectedInbox);

    api("/api/compose-multi/create-by-user-inbox", "POST", {
      thread_topic: topic,
      thread_participants: participants,
      thread_author: selectedInbox,
      message: message,
      sender: selectedInbox,
    })
      .then((data) => {
        handleThread({
          thread_id: data.thread_id,
          thread_participants: participants,
          sender: selectedInbox,
          thread_topic: topic,
          thread_author: selectedInbox,
          created_at: data.created_at,
          modified_at: data.modified_at,
          last_read_message_id: data.message_id,
          thread_read: true,
        });
        socket.emit("send-message", {
          messageText: message,
          threadParticipants: participants,
          sender: selectedInbox,
          threadId: data.thread_id,
          threadTopic: topic,
          threadAuthor: selectedInbox,
          messageId: data.message_id,
          hasAttachment: false,
          attachmentObj: {},
        });

        onClose();
        setRecipientsList([]);
        setRecipientsValidityObj({});
        setTopic(null);
        setMessage(null);
        setRecipientInput(null);
        setRecipientInputExists(false);
        setRecipientSenderError(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleRecipients(inputValue) {
    setRecipientInput(inputValue);
    setRecipientInputExists(false);
    setRecipientSenderError(false);
    if (inputValue.trim() && inputValue.endsWith(" ")) {
      const trimmedRecipientInput = inputValue.trim();
      if (!recipientsList.includes(trimmedRecipientInput)) {
        if (inputValue.trim() !== selectedInbox) {
          setRecipientInput("");
          setRecipientsList((prevState) => [
            ...prevState,
            trimmedRecipientInput,
          ]);

          api("/api/inboxes/verify-if-exists", "POST", {
            relaybeam_id: trimmedRecipientInput,
          })
            .then((data) => {
              const newRecipientValidityObj = {};
              newRecipientValidityObj[trimmedRecipientInput] = data.exists;
              const returnedObj = Object.assign(
                {},
                recipientsValidityObj,
                newRecipientValidityObj
              );

              setRecipientsValidityObj(returnedObj);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          setRecipientSenderError(true);
        }
      } else {
        setRecipientInputExists(true);
      }
    }
  }

  function addRecipient() {
    const trimmedRecipientInput = recipientInput.trim();
    if (!recipientsList.includes(trimmedRecipientInput)) {
      if (trimmedRecipientInput !== selectedInbox) {
        setRecipientsList((prevState) => [...prevState, trimmedRecipientInput]);
        setRecipientInput("");

        api("/api/inboxes/verify-if-exists", "POST", {
          relaybeam_id: trimmedRecipientInput,
        })
          .then((data) => {
            const newRecipientValidityObj = {};
            newRecipientValidityObj[trimmedRecipientInput] = data.exists;
            const returnedObj = Object.assign(
              {},
              recipientsValidityObj,
              newRecipientValidityObj
            );

            setRecipientsValidityObj(returnedObj);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setRecipientSenderError(true);
      }
    } else {
      setRecipientInputExists(true);
    }
  }

  function removeRecipient(recipient, index) {
    let newRecipientsList = [...recipientsList];
    newRecipientsList.splice(index, 1);

    let newRecipientValidityObj = { ...recipientsValidityObj };
    delete newRecipientValidityObj[recipient];

    setRecipientsList(newRecipientsList);
    setRecipientsValidityObj(newRecipientValidityObj);
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent style={{ borderRadius: "20px" }}>
        <ModalHeader>Compose</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          style={{
            paddingBottom: "6px",
          }}
        >
          <FormControl isInvalid={recipientInputExists || recipientSenderError}>
            <FormLabel>To</FormLabel>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {recipientsList?.map((recipient, index) => {
                return (
                  <div>
                    <div className="recipient-container">
                      <div>{recipient}</div>
                      <div className="remove-recipient">
                        <DashCircle
                          style={{ marginTop: "2px", cursor: "pointer" }}
                          onClick={() => removeRecipient(recipient, index)}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        marginLeft: "10px",
                        fontSize: "15px",
                      }}
                    >
                      {recipientsValidityObj[recipient] ? (
                        <span style={{ color: "#4BB543" }}>Valid</span>
                      ) : (
                        <span style={{ color: "#FC100D" }}>Invalid</span>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="add-recipient-container">
              <div className="add-recipient-input">
                <Input
                  placeholder="Add recipients"
                  value={recipientInput}
                  onChange={(e) => handleRecipients(e.currentTarget.value)}
                />
                <div>
                  {recipientInputExists && (
                    <FormErrorMessage>
                      Recipient already added.
                    </FormErrorMessage>
                  )}
                  {recipientSenderError && (
                    <FormErrorMessage>
                      Can not add self as recipient.
                    </FormErrorMessage>
                  )}
                  {recipientInput !== null && recipientInput.trim() && (
                    <FormHelperText>
                      To add multiple recipients click on 'Add' or press 'Space'
                    </FormHelperText>
                  )}
                </div>
              </div>
              <Button
                style={{
                  backgroundColor: "#FF6200",
                  color: "white",
                  marginLeft: "10px",
                  flexShrink: "0",
                }}
                rightIcon={
                  <EnvelopePlus style={{ marginBottom: "2px" }} size="20" />
                }
                onClick={() => addRecipient()}
                isDisabled={
                  recipientInput === null ||
                  (recipientInput !== null && !recipientInput.trim())
                }
              >
                Add
              </Button>
            </div>
          </FormControl>
          <FormControl style={{ marginTop: "15px" }}>
            <FormLabel>Topic</FormLabel>
            <Input
              placeholder="Add topic"
              value={topic}
              onChange={(e) => setTopic(e.currentTarget.value)}
            />
          </FormControl>
          <FormControl style={{ marginTop: "15px" }}>
            <FormLabel>Message</FormLabel>
            <Textarea
              style={{ width: "100%" }}
              placeholder="Write a message..."
              value={message}
              onChange={(e) => setMessage(e.currentTarget.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          {Object.values(recipientsValidityObj).includes(false) && (
            <div style={{ color: "#FC100D" }}>Remove invalid recipients</div>
          )}
          <Button
            variant="outline"
            style={{ margin: "7px 10px" }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleComposeMulti()}
            style={{
              backgroundColor: "#0059B3",
              color: "white",
              margin: "7px 10px",
            }}
            rightIcon={<SendFill style={{ marginBottom: "2px" }} />}
            isDisabled={
              recipientsList.length === 0 ||
              !topic ||
              !message ||
              Object.values(recipientsValidityObj).includes(false)
            }
          >
            Send
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
