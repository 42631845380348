import React, { useEffect, useState } from "react";
import { useApi } from "../../contexts/ApiProvider";
import { useUser } from "../../contexts/UserProvider";
import Loader from "../loader/Loader";
import "./Files.css";
import {
  Card,
  CardBody,
  Divider,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import FileTypeIcon from "../FileTypeIcon";
import { CloudDownload, ThreeDotsVertical, Trash } from "react-bootstrap-icons";
import { fileSizeParser } from "../../utils/file-size-parser";
import { useFile } from "../../contexts/FileProvider";

export default function Files() {
  const { api } = useApi();
  const { user } = useUser();
  const { downloadFile } = useFile();

  const toast = useToast();

  const [files, setFiles] = useState([]);
  const [totalStorage, setTotalStorage] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getFiles();
  }, []);

  function getFiles() {
    setLoading(true);
    api("/api/files/get-files-by-user", "POST", {
      username: user.username,
    })
      .then((data) => {
        setFiles(data.files);
        const calcTotalStorage = data.files.reduce(
          (accumulator, currentValue) =>
            accumulator + Number(currentValue.file_size),
          0
        );
        setTotalStorage(calcTotalStorage);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleDownload(file) {
    downloadFile(file.file_key, file.file_name);
  }

  function handleDelete(file) {
    api("/api/files/delete", "POST", {
      objKey: file.file_key,
      fileId: file.file_id,
      username: user.username,
    })
      .then((data) => {
        toast({
          title: "File deleted successfully",
          status: "success",
          position: "top",
          duration: 9000,
          isClosable: true,
        });
        getFiles();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="files-container">
      <div className="files-page-title">Files</div>
      <Divider style={{ borderWidth: "1px", margin: "10px 0px" }} />
      <div className="total-storage-section">
        Total Storage Used: {fileSizeParser(totalStorage)}
      </div>
      <div className="file-details-section">
        {files &&
          files.length > 0 &&
          files.map((file, index) => {
            return (
              <Card className="file-details">
                <CardBody>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <FileTypeIcon
                        fileType={file.file_mimetype}
                        size={30}
                        color="#0059B3"
                      />
                    </div>

                    <div className="file-details-info">
                      <div className="file-name">{file.file_name}</div>
                      <div className="file-size">
                        {fileSizeParser(Number(file.file_size))}
                      </div>
                    </div>
                    <div>
                      <Menu
                        autoSelect={false}
                        isLazy={true}
                        placement="bottom-end"
                      >
                        <MenuButton
                          as={IconButton}
                          icon={<ThreeDotsVertical />}
                          variant={"ghost"}
                          isRound={true}
                          color="#0059B3"
                        ></MenuButton>
                        <MenuList
                          style={{
                            boxShadow:
                              "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                          }}
                        >
                          <MenuItem
                            icon={<CloudDownload size={18} />}
                            onClick={() => handleDownload(file)}
                          >
                            Download
                          </MenuItem>
                          <MenuItem
                            icon={<Trash size={18} />}
                            onClick={() => handleDelete(file)}
                          >
                            Delete
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </div>
                  </div>
                </CardBody>
              </Card>
            );
          })}
      </div>
    </div>
  );
}
