import React from "react";
import ChangePassword from "./user-profile/ChangePassword";
import InboxConfig from "./user-profile/InboxConfig";
import "./UserProfile.css";
import { Divider } from "@chakra-ui/react";
import UserDetails from "./user-profile/UserDetails";

export default function UserProfile({ handleLogout }) {
  return (
    <div className="user-profile-container">
      <UserDetails />
      <Divider />
      <InboxConfig handleLogout={handleLogout} />
      <Divider />
      <ChangePassword handleLogout={handleLogout} />
    </div>
  );
}
