import React, { useEffect, useRef, useState } from "react";
import "../MessageController.css";
import { IconButton, Tooltip, useDisclosure } from "@chakra-ui/react";
import AttachmentDialog from "../attachments/AttachmentDialog";
import { Paperclip, SendFill } from "react-bootstrap-icons";

export default function DirectMessageController({
  sendDirectMessage,
  selectedDirectConversation,
  selectedInbox,
  messageMode,
}) {
  const [message, setMessage] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const textBoxRef = useRef();

  function handleSend() {
    sendDirectMessage(message);
    setMessage("");
  }

  useEffect(() => {
    if (textBoxRef.current) {
      textBoxRef.current.style.height = "auto";
      textBoxRef.current.style.marginTop = "auto";

      const scrollHeight = textBoxRef.current.scrollHeight;
      const newHeight = Math.min(scrollHeight, 250);
      textBoxRef.current.style.height = `${newHeight - 18}px`;
      textBoxRef.current.style.marginTop = `-${newHeight - 70}px`;

      if (message === "") {
        textBoxRef.current.style.height = "50px";
      }
    }
  }, [message]);

  return (
    <div
      style={{
        height: "70px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "50px",
          padding: "10px 0px",
          margin: "0px 10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: "1 0 auto",
          }}
        >
          <IconButton
            icon={<Paperclip color="#0059B3" size={20} />}
            style={{
              color: "white",
              flex: "1 0 auto",
            }}
            onClick={onOpen}
          />
        </div>
      </div>
      <AttachmentDialog
        isOpen={isOpen}
        onClose={onClose}
        selectedDirectConversation={selectedDirectConversation}
        selectedInbox={selectedInbox}
        messageMode={messageMode}
        key={selectedDirectConversation.direct_conversation_id}
      />
      <div style={{ flex: "1 0 auto", display: "flex", padding: "10px 0px" }}>
        <textarea
          type="text"
          ref={textBoxRef}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="message-text"
          placeholder="Write a message..."
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "50px",
          padding: "10px 0px",
          margin: "0px 10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: "1 0 auto",
          }}
        >
          <Tooltip
            label="Please type a message"
            hasArrow
            placement="top"
            bg="gray.300"
            color="black"
            isDisabled={
              !(
                message === null ||
                (message !== null &&
                  typeof message === "string" &&
                  !message.trim())
              )
            }
          >
            <IconButton
              icon={<SendFill />}
              style={{
                backgroundColor: "#0059B3",
                color: "white",
                flex: "1 0 auto",
              }}
              isDisabled={
                message === null ||
                (message !== null &&
                  typeof message === "string" &&
                  !message.trim())
              }
              onClick={() => handleSend()}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
