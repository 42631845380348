import React, { useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Textarea,
  Card,
  CardBody,
  IconButton,
  Spinner,
} from "@chakra-ui/react";
import { SendFill, Trash, Upload } from "react-bootstrap-icons";
import { useApi } from "../../contexts/ApiProvider";
import { useUser } from "../../contexts/UserProvider";
import { useData } from "../../contexts/DataProvider";
import { useSocket } from "../../contexts/SocketProvider";

export default function AttachmentDialog({
  isOpen,
  onClose,
  selectedDirectConversation,
  selectedThread,
  selectedInbox,
  messageMode,
}) {
  const [message, setMessage] = useState(null);

  const [file, setFile] = useState(null);
  const fileInputRef = useRef();

  const [fileId, setFileId] = useState(null);
  const [objName, setObjName] = useState(null);
  const [objKey, setObjKey] = useState(null);

  const [showFileSizeInfo, setShowFileSizeInfo] = useState(false);

  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const { user } = useUser();
  const { api } = useApi();

  const socket = useSocket();

  const { sendDirectConversationMessage } = useData();

  function handleUploadButtonClick() {
    fileInputRef.current.click();
  }

  function handleFileInputChange(e) {
    const uploadFile = e.target.files[0];

    if (uploadFile.size <= 10485760) {
      //File should be less than or equal to 10 MB

      setShowFileSizeInfo(false);

      const newDate = new Date();

      const datePrefixString =
        newDate.getFullYear().toString().slice(-2) +
        (newDate.getMonth() + 1).toString().padStart(2, "0") +
        newDate.getDate().toString().padStart(2, "0") +
        newDate.getUTCHours().toString().padStart(2, "0") +
        newDate.getUTCMinutes().toString().padStart(2, "0") +
        newDate.getUTCSeconds().toString().padStart(2, "0");

      const objName = datePrefixString + "_" + uploadFile.name;
      const objKey = `users/uploads/${user.username}/${objName}`;

      setFileUploadLoading(true);
      api("/api/files/upload", "POST", {
        objKey: objKey,
        username: user.username,
        fileName: uploadFile.name,
        fileObjName: objName,
        fileSize: uploadFile.size,
        fileMimeType: uploadFile.type,
        fileUploadedBy: selectedInbox,
      }).then((data) => {
        setFileId(data.fileId);
        fetch(data.preSignedUrl, {
          method: "PUT",
          body: uploadFile,
          mode: "cors",
        })
          .then((response) => {
            if (response.ok) {
              setFile(uploadFile);
              setObjName(objName);
              setObjKey(objKey);
            } else {
              throw new Error("File upload failed");
            }
          })
          .catch((error) => {
            console.error("Error uploading file");
          })
          .finally(() => {
            setFileUploadLoading(false);
          });
      });
    } else {
      setShowFileSizeInfo(true);
    }
  }

  function handleDeleteFile() {
    setDeleteLoading(true);
    api("/api/files/delete", "POST", {
      objKey,
      fileId,
      username: user.username,
    })
      .then((data) => {
        setFile(null);
        setFileId(null);
        setObjKey(null);
        setObjName(null);
        setShowFileSizeInfo(false);
      })
      .catch((err) => {
        console.error("Error");
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  }

  function sendDirectMessage() {
    const attachmentObj = {
      attachment_name: file.name,
      attachment_object_name: objName,
      attachment_key: objKey,
      attachment_size: file.size,
      attachment_mimetype: file.type,
    };

    api("/api/direct-messages/send-with-attachment", "POST", {
      message: message,
      sender: selectedInbox,
      recipient: selectedDirectConversation.conversation_recipient,
      direct_conversation_id: selectedDirectConversation.direct_conversation_id,
      attachmentObj: attachmentObj,
      file_id: fileId,
    })
      .then((data) => {
        socket.emit("send-direct-message", {
          directConversationId:
            selectedDirectConversation.direct_conversation_id,
          sender: selectedInbox,
          recipient: selectedDirectConversation.conversation_recipient,
          message: message,
          messageId: data.messageId,
          hasAttachment: true,
          attachmentObj: attachmentObj,
        });
        sendDirectConversationMessage({
          directConversationId:
            selectedDirectConversation.direct_conversation_id,
          conversationRecipient:
            selectedDirectConversation.conversation_recipient,
          userInbox: selectedInbox,
          recipient: selectedDirectConversation.conversation_recipient,
          message: message,
          messageId: data.messageId,
          sender: selectedInbox,
          hasAttachment: true,
          attachmentObj: attachmentObj,
        });

        handleAttachmentDialogClose();
      })
      .catch((err) => {
        console.log("Error");
      });
  }

  function sendThreadMessage() {
    const attachmentObj = {
      attachment_name: file.name,
      attachment_object_name: objName,
      attachment_key: objKey,
      attachment_size: file.size,
      attachment_mimetype: file.type,
    };

    api("/api/thread-messages/send-with-attachment", "POST", {
      thread_id: selectedThread.thread_id,
      sender: selectedInbox,
      message: message,
      thread_participants: selectedThread.thread_participants,
      attachmentObj: attachmentObj,
      file_id: fileId,
    })
      .then((data) => {
        socket.emit("send-message", {
          messageText: message,
          threadParticipants: selectedThread.thread_participants,
          sender: selectedInbox,
          threadId: selectedThread.thread_id,
          threadTopic: selectedThread.thread_topic,
          threadAuthor: selectedThread.thread_author,
          messageId: data.messageId,
          hasAttachment: true,
          attachmentObj: attachmentObj,
        });

        handleAttachmentDialogClose();
      })
      .catch((err) => {
        console.log("Error");
      });
  }

  function handleSend() {
    if (messageMode === "single") {
      sendDirectMessage();
    } else if (messageMode === "multi") {
      sendThreadMessage();
    }
  }

  function handleAttachmentDialogClose() {
    setMessage(null);
    setFile(null);
    setFileId(null);
    setObjKey(null);
    setObjName(null);
    setShowFileSizeInfo(false);

    onClose();
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleAttachmentDialogClose}
      size="2xl"
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload Media</ModalHeader>
        <ModalCloseButton />
        <ModalBody style={{ paddingBottom: "6px" }}>
          <Input
            type="file"
            style={{ display: "none" }}
            id="upload-button"
            ref={fileInputRef}
            onChange={handleFileInputChange}
          />
          <label htmlFor="upload-button">
            <Button
              style={{
                backgroundColor: "#0059B3",
                color: "white",
                flex: "1 0 auto",
              }}
              leftIcon={<Upload />}
              onClick={handleUploadButtonClick}
            >
              Upload
            </Button>
          </label>
          {showFileSizeInfo && (
            <div
              style={{
                display: "flex",
                margin: "15px 5px",
                alignItems: "center",
                color: "red",
              }}
            >
              Selected file should not be greater than 10 MB
            </div>
          )}
          {fileUploadLoading && (
            <div
              style={{
                display: "flex",
                margin: "15px 5px",
                alignItems: "center",
              }}
            >
              <Spinner
                thickness="2px"
                speed="0.65s"
                emptyColor="#FF6200"
                color="#0059B3"
                size="sm"
                style={{ marginRight: "5px" }}
              />
              <div>Uploading...</div>
            </div>
          )}
          {file && !fileUploadLoading && (
            <Card style={{ marginTop: "15px" }}>
              <CardBody>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#0059B3",
                    }}
                  >
                    {file.name}
                  </div>
                  <div>
                    <IconButton
                      icon={<Trash color="red" />}
                      onClick={() => handleDeleteFile()}
                      variant={"outline"}
                      isRound={true}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          )}
          {deleteLoading && (
            <div
              style={{
                display: "flex",
                margin: "15px 5px",
                alignItems: "center",
              }}
            >
              <Spinner
                thickness="2px"
                speed="0.65s"
                emptyColor="#FF6200"
                color="#0059B3"
                size="sm"
                style={{ marginRight: "5px" }}
              />
              Deleting...
            </div>
          )}
          <FormControl
            style={{ marginTop: "18px", marginRight: "5px", marginLeft: "5px" }}
          >
            <FormLabel>Message</FormLabel>
            <Textarea
              style={{ width: "100%" }}
              placeholder="Write a message..."
              onChange={(e) => setMessage(e.currentTarget.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            style={{ margin: "7px 10px" }}
            onClick={() => handleAttachmentDialogClose()}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleSend()}
            style={{
              backgroundColor: "#0059B3",
              color: "white",
              margin: "7px 10px",
            }}
            rightIcon={<SendFill style={{ marginBottom: "2px" }} />}
            isDisabled={file === null}
          >
            Send
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
