import React, { useEffect, useState } from "react";
import "./Register.css";
import { ReactComponent as RelayBeam } from "../graphics/RelayBeam.svg";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  InputGroup,
  Link as ChakraLink,
  ScaleFade,
  FormErrorMessage,
  FormHelperText,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
} from "@chakra-ui/react";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";

import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { format, formatISO } from "date-fns";

import { useAuth } from "../contexts/AuthProvider";
import { useUser } from "../contexts/UserProvider";
import { usernameValidator, passwordValidator } from "../utils/utils";
import { PencilSquare } from "react-bootstrap-icons";
import { useApi } from "../contexts/ApiProvider";

export default function Register() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassWord] = useState("");
  const [fullName, setFullName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [dayPickerComp, setDayPickerComp] = useState("");

  const [usernameAvailable, setUsernameAvailable] = useState(false);
  const [usernameAvailabilityCheck, setUsernameAvailabilityCheck] =
    useState(false);

  const [usernameValidity, setUsernameValidity] = useState(false);
  const [passwordValidity, setPasswordValidity] = useState(false);

  const [usernameInputFocus, setUsernameInputFocus] = useState(false);

  const { setToken } = useAuth();
  const { getUser } = useUser();
  const { api } = useApi();

  const navigate = useNavigate();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (username !== "" && usernameValidity) {
        api("/api/register/check-username-availability", "POST", {
          username: username,
        })
          .then((data) => {
            if (data.available) {
              setUsernameAvailable(true);
              setUsernameAvailabilityCheck(true);
            } else {
              setUsernameAvailable(false);
              setUsernameAvailabilityCheck(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [username]);

  function handleSubmit(e) {
    e.preventDefault();

    api("/api/register/user", "POST", {
      username: username,
      password: password,
      full_name: fullName,
      date_of_birth: dateOfBirth,
    })
      .then((data) => {
        setToken(data.token);
        getUser(username);
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleUsername(username) {
    const isUsernameValid = usernameValidator(username);
    if (isUsernameValid) {
      setUsernameValidity(true);
    } else {
      setUsernameValidity(false);
    }
    setUsername(username);
    setUsernameAvailabilityCheck(false);
  }

  function handlePassword(password) {
    const isPasswordValid = passwordValidator(password);
    if (isPasswordValid) {
      setPasswordValidity(true);
    } else {
      setPasswordValidity(false);
    }
    setPassword(password);
  }

  function handleDateOfBirth(dateOfBirth) {
    setDateOfBirth(formatISO(dateOfBirth, { representation: "date" }));
    setDayPickerComp(dateOfBirth);
  }

  return (
    <div className="register-container">
      <ScaleFade initialScale={0.9} in={true}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Box boxShadow="lg" className="register-box">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <RelayBeam className="relaybeam-logo" />
            </div>

            <div style={{ textAlign: "center", color: "gray" }}>
              Welcome to the beta version! Your insights and engagement are key
              as we evolve. Let's innovate together!
            </div>

            <Box my={4} textAlign="left">
              <form onSubmit={handleSubmit}>
                <FormControl isRequired>
                  <FormLabel style={{ fontSize: "15px" }}>FULL NAME</FormLabel>
                  <InputGroup>
                    <Input
                      size="md"
                      onChange={(e) => setFullName(e.currentTarget.value)}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl
                  isRequired
                  mt={6}
                  isInvalid={
                    (!usernameAvailable &&
                      usernameAvailabilityCheck &&
                      username !== "") ||
                    (username !== "" && !usernameValidity)
                  }
                >
                  <FormLabel style={{ fontSize: "15px" }}>USERNAME</FormLabel>
                  <InputGroup>
                    <Input
                      size="md"
                      onChange={(e) => handleUsername(e.currentTarget.value)}
                      onFocus={() => setUsernameInputFocus(true)}
                      onBlur={() => setUsernameInputFocus(false)}
                    />
                  </InputGroup>
                  {usernameAvailabilityCheck &&
                    !usernameAvailable &&
                    username !== "" && (
                      <FormErrorMessage>
                        Username is not available
                      </FormErrorMessage>
                    )}
                  {username !== "" &&
                    !usernameValidity &&
                    (username.length < 2 || username.length > 15) && (
                      <FormErrorMessage>
                        Invalid - Username should be of 2-15 characters.
                      </FormErrorMessage>
                    )}
                  {username !== "" &&
                    !usernameValidity &&
                    username.length >= 2 &&
                    username.length <= 15 && (
                      <FormErrorMessage>
                        Invalid - Username should only have lowercase letters,
                        numbers, hyphens - or underscores _ with first and last
                        character only lowercase letter or number.
                      </FormErrorMessage>
                    )}
                  {usernameAvailabilityCheck &&
                    usernameAvailable &&
                    username !== "" && (
                      <FormHelperText style={{ color: "green" }}>
                        Username is available.
                      </FormHelperText>
                    )}
                  {usernameInputFocus && (
                    <FormHelperText>
                      Username should only have lowercase letters, numbers,
                      hyphens - or underscores _ with first and last character
                      only lowercase letter or number.
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  isRequired
                  mt={6}
                  isInvalid={password.length > 0 && !passwordValidity}
                >
                  <FormLabel style={{ fontSize: "15px" }}>PASSWORD</FormLabel>
                  <InputGroup>
                    <Input
                      type="password"
                      size="md"
                      onChange={(e) => handlePassword(e.currentTarget.value)}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    Password should be at least 8 characters long.
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isRequired
                  mt={6}
                  isInvalid={
                    confirmPassword !== "" && password !== confirmPassword
                  }
                >
                  <FormLabel style={{ fontSize: "15px" }}>
                    CONFIRM PASSWORD
                  </FormLabel>
                  <InputGroup>
                    <Input
                      type="password"
                      size="md"
                      onChange={(e) =>
                        setConfirmPassWord(e.currentTarget.value)
                      }
                    />
                  </InputGroup>
                  {confirmPassword !== "" && password !== confirmPassword && (
                    <FormErrorMessage>Passwords should match.</FormErrorMessage>
                  )}
                  {confirmPassword !== "" && password === confirmPassword && (
                    <FormHelperText style={{ color: "green" }}>
                      Passwords match.
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl isRequired mt={6}>
                  <FormLabel style={{ fontSize: "15px" }}>
                    DATE OF BIRTH
                  </FormLabel>
                  <Popover isLazy closeOnBlur={false}>
                    <PopoverTrigger>
                      <Button
                        width="full"
                        style={{
                          border: "1px solid #E3E3E3",
                          backgroundColor: "white",
                        }}
                        rightIcon={<PencilSquare size={20} />}
                      >
                        {dateOfBirth === "" && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            Select
                          </div>
                        )}
                        {dateOfBirth !== "" && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div>{format(dayPickerComp, "PP")}</div>
                          </div>
                        )}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverBody>
                        <DayPicker
                          mode="single"
                          captionLayout="dropdown-buttons"
                          fromYear={1900}
                          toYear={2024}
                          required
                          selected={dayPickerComp}
                          onSelect={handleDateOfBirth}
                        />
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </FormControl>
                <Button
                  style={{ backgroundColor: "#0059B3", color: "white" }}
                  width="full"
                  mt={10}
                  type="submit"
                  isDisabled={
                    username.length === 0 ||
                    !usernameAvailable ||
                    !usernameValidity ||
                    !usernameAvailabilityCheck ||
                    fullName.length === 0 ||
                    !passwordValidity ||
                    confirmPassword !== password ||
                    dateOfBirth === ""
                  }
                >
                  Continue
                </Button>
                <div style={{ marginTop: "20px", fontSize: "12px" }}>
                  By clicking on 'Continue', you agree to RelayBeam's{" "}
                  <ChakraLink
                    as={ReactRouterLink}
                    to="/terms"
                    isExternal
                    color="#0059B3"
                    style={{ fontWeight: "bold" }}
                  >
                    Terms of Service
                  </ChakraLink>{" "}
                  and{" "}
                  <ChakraLink
                    as={ReactRouterLink}
                    to="/privacy"
                    isExternal
                    color="#0059B3"
                    style={{ fontWeight: "bold" }}
                  >
                    Privacy Policy
                  </ChakraLink>
                  .
                </div>
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "30px",
                    fontSize: "15px",
                  }}
                >
                  <ChakraLink
                    as={ReactRouterLink}
                    to="/"
                    color="#FF6200"
                    style={{ fontWeight: "bold" }}
                  >
                    Already have an account?
                  </ChakraLink>
                </div>
              </form>
            </Box>
          </Box>
        </div>
      </ScaleFade>
    </div>
  );
}
