import React from "react";
import { Envelope } from "react-bootstrap-icons";
import { useUser } from "../contexts/UserProvider";
import "./InboxList.css";
import { useHorizontalScroll } from "./custom-hooks/useHorizontalScroll";

export default function InboxList({ handleInbox, selectedInbox, navSize }) {
  const { user, userInboxes, userInboxesReadStatus } = useUser();
  const horizontalScrollRef = useHorizontalScroll();

  return (
    <div className="inbox-list-container">
      <div className="username">{`${user.username}@`}</div>

      <div ref={horizontalScrollRef} className="inbox-container">
        {userInboxes &&
          userInboxes.map((inbox, index) => {
            const inboxRead =
              userInboxesReadStatus[inbox].thread_read &&
              userInboxesReadStatus[inbox].direct_conversation_read;

            return (
              <div
                className={
                  selectedInbox === inbox
                    ? "active-inbox"
                    : inboxRead
                    ? "inbox"
                    : "unread-inbox inbox"
                }
                onClick={() => handleInbox(inbox)}
              >
                {selectedInbox !== inbox && !inboxRead && (
                  <div className="unread-indicator" />
                )}
                <div
                  className={
                    selectedInbox === inbox
                      ? "active-inbox-avatar"
                      : "inbox-avatar"
                  }
                >
                  <Envelope size={30} />
                </div>

                <div
                  className={
                    selectedInbox === inbox
                      ? "active-inbox-label"
                      : "inbox-label"
                  }
                >
                  {inbox.substring(inbox.indexOf("@") + 1)}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
